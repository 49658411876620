import * as UserModule from '../../../Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
const init = () => ({
  type: User.SUSPEND_USER_INIT,
});

const success = (data) => ({
  type: User.SUSPEND_USER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.SUSPEND_USER_FAILURE,
});

const suspendUser = (uuid, action) => async (dispatch) => {
  dispatch(init());
  const isSuspend = action === 'suspend';
  const id = toast.loading(`Trwa ${isSuspend ? 'zawieszanie' : 'przywracanie'} użytkownika...`);
  try {
    const { data } = await UserModule.Connector.suspendUser(uuid);
    toast.update(id, {
      render: `Pomyślnie ${isSuspend ? 'zawieszono' : 'przywrócono'} użytkownika`,
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    dispatch(success(data.user));
  } catch (err: any) {
    dispatch(failure());
    toast.update(id, {
      render: `Wystąpił błąd podczas ${isSuspend ? 'zawieszania' : 'przywracania'} użytkownika, sprawdź później`,
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
  }
};

export default suspendUser;
