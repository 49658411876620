import { useState, useEffect, useRef } from 'react';
import { Input, Textarea, Slider, Button } from 'Shared/components';
import { Field, Formik } from 'formik';
import { SVG_TYPE, VALIDATION_MESSAGES } from 'Shared/enums';
import { RemoveTargetModal } from './components';
import * as Yup from 'yup';

type SidebarTargetType = {
  selectedNode: any;
  setSelectedNode: any;
  nodes: any;
  setNodes: any;
  parameters: any;
  initialValues: any;
  setFormValues: any;
  handleDeleteNode: any;
};

const SidebarTarget = ({
  handleDeleteNode,
  selectedNode,
  nodes,
  setNodes,
  setSelectedNode,
  parameters,
  initialValues,
  setFormValues,
}: SidebarTargetType) => {
  const [modifiedParameters, setModifiedParameters] = useState(null);
  const [validationSchema, setValidationSchema] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const sidebarTargetClass = `sidebar-target ${selectedNode ? 'sidebar-target--active' : ''}`;
  const formikRef = useRef();
  const language = sessionStorage.getItem('language');
  const isEnLanguage = language === 'EN';

  useEffect(() => {
    const graphicChangeCounts = {};
    const modifiedParams = parameters.map((item) => {
      if (item.graphicChange === 'noGraphics') {
        if (graphicChangeCounts[item.graphicChange]) {
          graphicChangeCounts[item.graphicChange]++;
          item.graphicChange = `noGraphics_${graphicChangeCounts[item.graphicChange] - 1}`;
        } else {
          graphicChangeCounts[item.graphicChange] = 1;
        }
      }
      return item;
    });
    setModifiedParameters(modifiedParams);
  }, []);
  const updateClass = (existingClassString, key, value) => {
    const classesArray = existingClassString.split(' ');
    const updatedClasses = classesArray.filter((cls) => !cls.startsWith(`${key}--`));
    if (value) {
      updatedClasses.push(`${key}--${value}`);
    }
    return updatedClasses.join(' ').trim();
  };
  const handleChange = (data, setFieldValue, scaleLevel) => {
    const firstParam = Object.keys(data)[0];
    const valueFirstParam = data[firstParam];
    setFieldValue(firstParam, valueFirstParam);
    const index = nodes.findIndex((item) => item.id === selectedNode?.id);
    if (index === -1) return;
    const existingClassString = nodes[index]?.className || '';
    let updatedClassString = existingClassString;
    if (data.circleSize !== undefined) {
      updatedClassString = updateClass(updatedClassString, 'circleSize', handleParameterToClass(data.circleSize, Number(scaleLevel)));
    }
    if (data.contourSize !== undefined) {
      updatedClassString = updateClass(updatedClassString, 'contourSize', handleParameterToClass(data.contourSize, Number(scaleLevel)));
    }
    if (data.colorSaturation !== undefined) {
      updatedClassString = updateClass(updatedClassString, 'colorSaturation', handleParameterToClass(data.colorSaturation, Number(scaleLevel)));
    }
    const newNodes = [...nodes];
    newNodes[index] = {
      ...nodes[index],
      data: { ...nodes[index].data, ...data },
      className: updatedClassString,
    };
    // console.log(newNodes[index]);
    // setSelectedNode({ ...selectedNode, data: { ...selectedNode?.data, ...data } });

    setNodes(newNodes);

    setFormValues((prevState) => ({
      ...prevState,
      [firstParam]: valueFirstParam,
    }));
  };

  const handleParameterToClass = (numb, max) => {
    const maxInScaleOfTen = 10;
    if (numb === 1) {
      return 1;
    } else if (max < 10) {
      const classNumb = Math.ceil((numb / max) * maxInScaleOfTen);
      return classNumb;
    } else {
      return numb;
    }
  };

  useEffect(() => {
    const validationUpdateSchema = {};
    for (const key in initialValues) {
      if (key != 'descript' && key != 'label') {
        validationUpdateSchema[key] = Yup.string().required(isEnLanguage ? `${VALIDATION_MESSAGES.REQUIRED_EN}` : `${VALIDATION_MESSAGES.REQUIRED}`);
      } else if (key === 'label') {
        validationUpdateSchema[key] = Yup.string()
          .required(isEnLanguage ? `${VALIDATION_MESSAGES.REQUIRED_EN}` : `${VALIDATION_MESSAGES.REQUIRED}`)
          .matches(
            /^[^\s].*[^\s]$/,
            isEnLanguage ? VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE_EN : VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE,
          )
          .min(3, isEnLanguage ? VALIDATION_MESSAGES.MIN_LENGTH_3_EN : VALIDATION_MESSAGES.MIN_LENGTH_3)
          .max(30, isEnLanguage ? VALIDATION_MESSAGES.MAX_LENGTH_200_EN : VALIDATION_MESSAGES.MAX_LENGTH_200);
      }
    }
    setValidationSchema(validationUpdateSchema);
  }, [initialValues]);

  const handleClickSubmit = async (values) => {
    await setSelectedNode({ ...selectedNode, data: { ...values } });
    const reactPlane = document.getElementsByClassName('react-flow__pane') as HTMLCollectionOf<HTMLElement>;
    reactPlane[0].click();
  };

  const handleRemoveTarget = () => {
    if (nodes.length === 1) {
      handleDeleteNode();
      setNodes([]);
      setIsModalOpen(false);
    } else {
      const newNodes = nodes.filter((item) => item.id !== selectedNode.id);
      setNodes(newNodes);
      handleDeleteNode();
      setIsModalOpen(false);
    }
  };

  return (
    <div className={sidebarTargetClass}>
      <RemoveTargetModal handleRemoveTarget={() => handleRemoveTarget()} isOpenModal={isModalOpen} close={() => setIsModalOpen(false)} />
      <div className="sidebar-target__heading">{isEnLanguage ? 'Goal edit' : 'Edycja celu'}</div>
      {initialValues && (
        <Formik
          validationSchema={Yup.object(validationSchema)}
          initialValues={initialValues}
          validateOnBlur={false}
          enableReinitialize
          innerRef={formikRef}
          onSubmit={async (values) => handleClickSubmit(values)}>
          {({ handleSubmit, errors, setFieldValue, values }) => {
            useEffect(() => {
              setFormValues(values);
            }, [values]);
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  label={isEnLanguage ? 'Name your goal' : 'Nazwij swój cel'}
                  name="label"
                  placeholder={isEnLanguage ? 'Enter goal name' : 'Wpisz nazwę celu'}
                  required
                  component={Input}
                  treshold={0}
                  maxLength={32}
                  errors={errors}
                  onChange={(label) => handleChange({ label: label?.target?.value }, setFieldValue, null)}
                />
                <Field
                  label={isEnLanguage ? 'Describe goal' : 'Opisz cel'}
                  name="descript"
                  placeholder=""
                  component={Textarea}
                  errors={errors}
                  variant="password"
                  onChange={(text) => handleChange({ descript: text }, setFieldValue, null)}
                />
                {modifiedParameters &&
                  modifiedParameters.length > 0 &&
                  modifiedParameters.map((item) => {
                    const graphicChange = item.graphicChange;
                    const marks = {};
                    for (let i = 0; i < item.titles.length; i++) {
                      const isLeft = i === 0;
                      const isRight = i === item.titles.length - 1;
                      if (item.titles[i] !== '') {
                        marks[i + 1] = {
                          label: (
                            <div
                              onClick={() => setFieldValue('graphicChange', i + 1)}
                              className={`slider__marks ${isLeft ? 'slider__marks--left' : ''} ${isRight ? 'slider__marks--right' : ''}`}>
                              <span>{i + 1}</span>
                              <span>{item.titles[i]}</span>
                            </div>
                          ),
                        };
                      } else {
                        marks[i + 1] = (i + 1).toString();
                      }
                    }

                    return (
                      <div key={item.key}>
                        <Field
                          id={item.key}
                          key={item.key}
                          label={item.title}
                          descript={item.text}
                          name={graphicChange}
                          component={Slider}
                          errors={errors}
                          scaleLevel={parseInt(item.scaleLevel)}
                          onChange={(e) => handleChange({ [graphicChange]: e }, setFieldValue, item.scaleLevel)}
                          marks={marks}
                          value={selectedNode?.data ? selectedNode?.data[graphicChange] : 0}
                          required
                        />
                      </div>
                    );
                  })}
                <Button
                  className="btn-delete"
                  svg_type={SVG_TYPE.BIN}
                  label={isEnLanguage ? 'Delete goal' : 'Usuń cel'}
                  stroke
                  onClick={() => setIsModalOpen(true)}
                />
                {/* <Button label="Dodaj kolejny cel + " onClick={() => handleAddTarget()} /> */}
                {/* <Button label="submit" onClick={() => handleSubmit()} /> */}
                <Button className="submitSidebar hidden" type="submit" label="submit" />
              </form>
            );
          }}
        </Formik>
      )}
    </div>
  );
};

export default SidebarTarget;
