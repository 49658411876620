import axios, { AxiosRequestConfig } from 'axios';
import { store } from 'Store/Store';
import { success } from 'Store/Actions/Auth/logout';
import { history } from 'Store/Reducers/RootReducer';
import { setRefreshToken } from 'Store/Actions/AuthActions';
import { ROUTES } from '../enums'

declare module 'axios' {
  interface AxiosRequestConfig {
    isPublic?: boolean;
    inBackground?: boolean;
    retry?: boolean;
  }
}
// axios.defaults.withCredentials = true;

const axiosPlugin = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
});

axiosPlugin.defaults.headers.post['Content-Type'] = 'application/json';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// axiosPlugin.interceptors.request.use(
//   (config: AxiosRequestConfig) => {
//     if (store.getState().authStore.token) {
//       config.headers['Authorization'] = `Bearer ${store.getState().authStore.token}`;
//     }
//     return config;
//   },
//   (error) => {
//     Promise.reject(error);
//   },
// );

axiosPlugin.interceptors.request.use(
  (config) => {
    const copyConfig = config;
    if (store.getState().authStore.token) {
      copyConfig.headers.Authorization = `Bearer ${store.getState().authStore.token}`;
    }
    return copyConfig;
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosPlugin.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    const originalRequest = error.config;

    if (typeof error.response !== 'undefined' && error.response.status === 401) {
      if (originalRequest.url === `${originalRequest.baseURL}auth/refresh`) {
        store.dispatch(success());
        history.push('/login');
        return Promise.reject(error);
      } else if (!isRefreshing) {
        isRefreshing = true;
        originalRequest.retry = true;

        // Poniżej wykonujemy odświeżanie tokena autoryzacyjnego
        return new Promise(function (resolve, reject) {
          axiosPlugin
            .post(`${process.env.REACT_APP_API_URL}auth/refresh`, null, { retry: true })
            .then(({ data }) => {
              store.dispatch(setRefreshToken(data));
              originalRequest.headers.Authorization = `Bearer ${data.token}`;
              processQueue(null, data.token);
              resolve(
                axios(originalRequest).then((response) => {
                  return response;
                }),
              );
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
              history.push('/login');
              const loginContent = document.getElementById('loginContent');
              if(!loginContent) window.location.href = ROUTES.LOGIN;
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      }
    }

    return Promise.reject(error);
  },
);


export const config = (params?: any, headers?: any) => {
  const configRq: AxiosRequestConfig = {};
  if (params) {
    configRq.params = params;
  }
  if (headers) {
    configRq.headers = headers;
  }
  return configRq;
};

export default axiosPlugin;
