import { connect } from 'react-redux';
import TestsPage from './Main';
import { bindActionCreators } from 'redux';
import { getStudies, deleteStudy } from 'Store/Actions/Study';
import { getStudies as getStudiesSelector } from 'Store/Selectors/StudySelector';


type ConnectedP = {
  tests: any;
};

type DispatchedP = {
  actions: {
    getStudies: (data) => any;
    deleteStudy: (data) => any
  };
};
const mapStateToProps = (state) => ({
  tests: getStudiesSelector(state)
});

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getStudies: getStudies,
      deleteStudy: deleteStudy,
    },
    dispatch,
  ),
});
export type MainType = ConnectedP & DispatchedP;

export default connect(mapStateToProps, mapDispatchToProps)(TestsPage);
