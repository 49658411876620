import { useEffect, useState, useCallback } from 'react';
import SelectEdgeOptions from './SelectEdgeOptions';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { default as RcSlider } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { MarkerType } from 'reactflow';

type EdgeControlType = {
  edges: any;
  setEdges: any;
  setIsMissingParameter: any;
  isMissingParameter: any;
  setWhichEdgeControlsIsOpen?: any;
  whichEdgeControlsIsOpen?: any;
  isEdgeControlsOpened?: any;
  setIsEdgeControlsOpened?: any;
  whichNodeIsEdited?: any;
  setWhichNodeIsEdited?: any;
  isEdgeSliderOpen?: any;
  setIsEdgeSliderOpen?: any;
};
const EdgeControls = ({
  edges,
  setEdges,
  setIsMissingParameter,
  isMissingParameter,
  setWhichEdgeControlsIsOpen,
  whichEdgeControlsIsOpen,
  isEdgeControlsOpened,
  setIsEdgeControlsOpened,
  whichNodeIsEdited,
  setWhichNodeIsEdited,
  isEdgeSliderOpen,
  setIsEdgeSliderOpen,
}: EdgeControlType) => {
  const [isInfluenceError, setIsInfluenceError] = useState(true);
  const [isInfluencePowerError, setIsInfluencePowerError] = useState(true);
  const selectedEdgeStroke = whichNodeIsEdited?.style.stroke;
  const red = '#CC0000';
  const green = '#006600';
  const influenceError = isInfluenceError && isMissingParameter;
  const influencePowerError = isInfluencePowerError && isMissingParameter;
  const language = sessionStorage.getItem('language');
  const isEnLanguage = language === 'EN';

  const handleEdgeClick = useCallback(
    (event) => {
      const clickedEdge = event.target.closest('.react-flow__edge');
      const clickedEdgeNodeValue = clickedEdge && clickedEdge.attributes[3].nodeValue;
      if (clickedEdgeNodeValue) {
        setIsEdgeControlsOpened(true);
        const edgeRect = clickedEdge.getBoundingClientRect();
        const edgeCenterX = edgeRect.left + edgeRect.width / 2;
        const edgeCenterY = edgeRect.top + edgeRect.height / 2;
        const edgeControls = document.getElementById('edge-controls');
        edgeControls.classList.remove('edge-controls-hidden');
        const newDivWidth = edgeControls.offsetWidth;
        const newDivHeight = edgeControls.offsetHeight;
        const newDivLeft = edgeCenterX - newDivWidth / 2;
        const newDivTop = edgeCenterY - newDivHeight / 2;
        edgeControls.style.left = newDivLeft + 'px';
        edgeControls.style.top = newDivTop + 'px';
        edges.map((item) => {
          if (
            clickedEdgeNodeValue.includes(`${item.sourceHandle}-${item.target}`) &&
            clickedEdgeNodeValue.includes(item.targetHandle) &&
            clickedEdgeNodeValue.includes(`reactflow__edge-${item.source}`)
          ) {
            setWhichNodeIsEdited(item);
          }
        });
      }

      if (!clickedEdgeNodeValue) {
        if (edges.length > 0) {
          if (edges.some((item) => item.style.stroke === 'black' && (item.style.strokeWidth === 0.5 || item.style.strokeWidth === '0.5'))) {
            setIsMissingParameter(true);
            setIsInfluencePowerError(true);
            setIsInfluenceError(true);
          } else if (edges.some((item) => item.style.stroke === 'black')) {
            setIsMissingParameter(true);
            setIsInfluenceError(true);
          } else if (edges.some((item) => item.style.strokeWidth === 0.5 || item.style.strokeWidth === '0.5')) {
            setIsMissingParameter(true);
            setIsInfluencePowerError(true);
          } else {
            const edgeControls = document.getElementById('edge-controls');
            edgeControls.classList.add('edge-controls-hidden');
            setIsInfluenceError(false);
            setIsInfluencePowerError(false);
            setIsMissingParameter(false);
            setIsEdgeControlsOpened(false);
            setWhichNodeIsEdited(null);
          }
        }
      }
    },
    [edges],
  );

  useEffect(() => {
    const container = document.querySelector('.react-flow');
    container.addEventListener('click', handleEdgeClick);

    return () => {
      container.removeEventListener('click', handleEdgeClick);
    };
  }, [handleEdgeClick]);

  const handleChangeInfluence = (e) => {
    const modifiedEdges = edges.map((item) => {
      const isThisSameElement = item.id === whichNodeIsEdited.id;
      if (isThisSameElement) {
        setWhichNodeIsEdited({ ...item, style: { ...item.style, stroke: `${e.value === 'inhibitory' ? red : green}` } });
        setIsInfluenceError(false);
        if (item.style.strokeWidth !== 0.5 && item.style.strokeWidth !== '0.5') {
          setIsMissingParameter(false);
          setWhichEdgeControlsIsOpen(null);
        }
        return {
          ...item,
          markerEnd: { color: `${e.value === 'inhibitory' ? red : green}`, type: 'arrow' },
          style: { ...item.style, stroke: `${e.value === 'inhibitory' ? red : green}` },
        };
      }
      return item;
    });
    setEdges(modifiedEdges);
  };

  const handleChangePowerInfluence = (e) => {
    const modifiedEdges = edges.map((item) => {
      const isThisSameElement = item.id === whichNodeIsEdited.id;
      if (isThisSameElement) {
        // setIsSliderOpen(false);
        setIsInfluencePowerError(false);
        if (item.style.stroke !== 'black') {
          setIsMissingParameter(false);
          setWhichEdgeControlsIsOpen(null);
        }
        setWhichNodeIsEdited({
          ...item,
          style: { ...item.style, strokeWidth: e },
          markerEnd: { type: MarkerType.Arrow, color: item.markerEnd.color },
        });
        let size;
        if (e <= 3) {
          size = {
            width: 40 / e,
            height: 40 / e,
          };
        }
        return { ...item, style: { ...item.style, strokeWidth: e }, markerEnd: { ...size, type: MarkerType.Arrow, color: item.markerEnd.color } };
      }
      return item;
    });
    setEdges(modifiedEdges);
  };

  const handleRemoveEdge = () => {
    const modifiedEdges = edges.filter((item) => item.id !== whichNodeIsEdited.id);
    setEdges(modifiedEdges);
    setIsEdgeControlsOpened(false);
    setIsMissingParameter(false);
    setWhichEdgeControlsIsOpen(null);
    setWhichNodeIsEdited(null);
  };

  const handleOpenPowerInfluenceSlider = () => {
    if (whichEdgeControlsIsOpen === 'powerInfluence') {
      setWhichEdgeControlsIsOpen(null);
      setIsEdgeSliderOpen(false);
    } else {
      setWhichEdgeControlsIsOpen('powerInfluence');
      setIsEdgeSliderOpen(true);
    }
  };

  const isEmpty = whichNodeIsEdited?.style.strokeWidth === 0.5;

  return (
    <>
      <div id="edge-controls" className={isEdgeControlsOpened ? '' : 'edge-controls-hidden'}>
        <div className={`edge-controls__select`} onClick={() => setIsEdgeSliderOpen(false)}>
          <SelectEdgeOptions
            name="influence"
            setWhichEdgeControlsIsOpen={setWhichEdgeControlsIsOpen}
            isError={influenceError}
            value={{
              value: selectedEdgeStroke === 'black' ? 'black' : `${selectedEdgeStroke === green ? 'complementary' : 'inhibitory'}`,
              label:
                selectedEdgeStroke === 'black'
                  ? `${isEnLanguage ? 'Influence' : 'Wpływ'}`
                  : `${selectedEdgeStroke === red ? `${isEnLanguage ? 'Negative' : 'Negatywny'}` : `${isEnLanguage ? 'Positive' : 'Pozytywny'}`}`,
            }}
            options={[
              { value: 'complementary', label: `${isEnLanguage ? 'Positive' : 'Pozytywny'}` },
              { value: 'inhibitory', label: `${isEnLanguage ? 'Negative' : 'Negatywny'}` },
            ]}
            onChange={(e) => handleChangeInfluence(e)}
            placeholder={isEnLanguage ? 'Influence' : 'Wpływ'}
          />
        </div>
        <div className="edge-controls__select edge-controls__select--disabled">
          <div className="edge-controls__select--onclick" onClick={() => handleOpenPowerInfluenceSlider()} />
          <SelectEdgeOptions
            setWhichEdgeControlsIsOpen={setWhichEdgeControlsIsOpen}
            isError={influencePowerError}
            noOptionsMessage=""
            placeholder={`${isEnLanguage ? 'Strength of influence' : 'Siła wpływu'}`}
            name="powerInfluence"
          />
          {isEdgeSliderOpen && (
            <div className="edge-controls__select-slider">
              <RcSlider
                onAfterChange={(e) => {
                  handleChangePowerInfluence(e);
                }}
                onChange={(e) => handleChangePowerInfluence(e)}
                marks={{
                  1: '1',
                  2: '2',
                  3: '3',
                  4: '4',
                  5: '5',
                }}
                defaultValue={whichNodeIsEdited?.style.strokeWidth === 0.5 ? 0 : whichNodeIsEdited?.style.strokeWidth}
                dots
                step={1}
                max={5}
                min={1}
                className={isEmpty ? 'slider--empty' : ''}
              />
              <div className="edge-controls__select-slider-label">
                <p>{isEnLanguage ? 'Very low' : 'Bardzo mała'}</p>
                <p>{isEnLanguage ? 'Very high' : 'Bardzo duża'}</p>
              </div>
            </div>
          )}
        </div>
        <div className="edge-controls__remove" onClick={() => handleRemoveEdge()}>
          <SVG type={SVG_TYPE.BIN} />
        </div>
      </div>
    </>
  );
};

export default EdgeControls;
