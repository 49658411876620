import { Router } from 'Shared/utils';

function App() {
  return (
    <>
      <Router />
    </>
  );
}
export default App;
