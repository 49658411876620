import { toast } from 'react-toastify';
import * as TestModule from '../../../Modules/StudyModule';
import { Study } from '../EnumTypes/ActionEnumTypes';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Study.DELETE_MAP_INIT,
});

const success = (data) => ({
  type: Study.DELETE_MAP_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: Study.DELETE_MAP_FAILURE,
});

const deleteMap = (form) => async (dispatch) => {
  const id = toast.loading('Trwa usuwanie mapy...');
  dispatch(init());
  try {
    const { data } = await TestModule.Connector.deleteMap({ mapUuid: form });
    toast.update(id, {
      render: 'Mapa została usunięta',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    dispatch(success(data.tests));
    return data;
  } catch (err: any) {
    toast.update(id, {
      render: 'Wystąpił błąd podczas usuwania mapy, spróbuj później',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
    dispatch(failure());
  }
};

export default deleteMap;
