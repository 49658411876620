// import * as AuthModule from '../../../Modules/AuthModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import * as AuthActionModel from '../Models/AuthActionModel';
import * as AuthModule from 'Modules/AuthModule';
import { toast } from 'react-toastify';

function init() {
  return {
    type: Auth.LOG_OUT_INIT,
  };
}

export const success = (): AuthActionModel.LogoutSuccess => ({
  type: Auth.LOG_OUT_SUCCESS,
});

const failure = (): AuthActionModel.LogoutFailure => ({
  type: Auth.LOG_OUT_FAILURE,
});

const logout = () => {
  return async (dispatch) => {
    dispatch(init());
    const id = toast.loading('Trwa wylogowywanie...');
    try {
      await AuthModule.Connector.logout();
      toast.update(id, {
        render: 'Wylogowano',
        type: toast.TYPE.SUCCESS,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
      });
      dispatch(success());
    } catch (err) {
      toast.update(id, {
        render: 'Nie udało się wylogować',
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
      });
      dispatch(failure());
    }
  };
};

export default logout;
