import * as AuthModule from 'Modules/AuthModule';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';
import { Auth } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

export const success = (phonePrefixes) => ({
  type: Auth.CHANGE_CURRENT_USER_PASSWORD_SUCCESS,
  payload: {
    phonePrefixes,
  },
});

const failure = () => ({
  type: Auth.CHANGE_CURRENT_USER_PASSWORD_FAILURE,
});

const changeCurrentUserPassword = (currentPassword, newPassword) => async (dispatch) => {
  const id = toast.loading('Trwa zmiana hasła...');
  try {
    const response = await AuthModule.Connector.changeCurrentUserPassword({ currentPassword, newPassword });
    dispatch(success(response.data.phonePrefixSerialize));
    toast.update(id, {
      render: 'Pomyślnie zmieniono hasło',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    return true;
  } catch (err: any) {
    dispatch(failure());
    const isThisSame = err.response.data?.messsages?.includes('an old password');
    const errorCurrentPassword = err.response.data?.message?.includes('Bad Password');
    if (isThisSame) {
      toast.update(id, {
        render: 'Nowe hasło powinno być inne niż obecne',
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
      });
    } else if (errorCurrentPassword) {
      toast.update(id, {
        render: 'Podano błędne obecne hasło',
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
      });
    } else {
      toast.update(id, {
        render: 'Wystąpił błąd',
        type: toast.TYPE.ERROR,
        isLoading: false,
        closeButton: true,
        autoClose: 3000,
        icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
      });
    }
    return err.response.data.message || err.response.data;
  }
};

export default changeCurrentUserPassword;
