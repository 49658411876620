import * as TestModule from '../../../Modules/StudyModule';
import { Study } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
const init = () => ({
  type: Study.ADD_STUDY_INIT,
});

const success = () => ({
  type: Study.ADD_STUDY_SUCCESS,
});

const failure = () => ({
  type: Study.ADD_STUDY_FAILURE,
});

const addStudy = (data) => async (dispatch) => {
  dispatch(init());
  const id = toast.loading('Trwa dodawanie użytkownika...');
  try {
    await TestModule.Connector.addStudy(data);
    toast.update(id, {
      render: 'Pomyślnie dodano badnie',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    dispatch(success());
    return true;
  } catch (err: any) {
    dispatch(failure());
    toast.update(id, {
      render: 'Wystąpił błąd podczas dodawania badania, sprawdź później',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default addStudy;
