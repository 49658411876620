import * as StudyModule from '../../../Modules/ReportModule';
import { Report } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

const init = () => ({
  type: Report.GET_GROUP_REPORT_INIT,
});

const success = (files) => ({
  type: Report.GET_GROUP_REPORT_SUCCESS,
  payload: {
    data: files,
  },
});

const failure = () => ({
  type: Report.GET_GROUP_REPORT_FAILURE,
});

const handleFileDownload = async (fileLink) => {
  const link = document.createElement('a');
  link.href = fileLink;
  link.setAttribute('download', '');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const getGroupReport = (mapUuid) => async (dispatch) => {
  dispatch(init());
  const id = toast.loading('Trwa generowanie raportu...');
  try {
    const { data } = await StudyModule.Connector.getGroupReport(mapUuid);
    dispatch(success(data));
    data.fileLink && handleFileDownload(data.fileLink);
    toast.update(id, {
      render: 'Raport jest gotowy do pobrania',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    return data;
  } catch (err: any) {
    dispatch(failure());
    toast.update(id, {
      render: 'Wystąpił błąd podczas generowania raportu',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
    return false;
  }
};

export default getGroupReport;
