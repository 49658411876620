import { ROUTES, USER_ROLES } from 'Shared/enums';
import { Route, Routes } from 'react-router-dom';
import LoginPage from 'Screens/LoginPage/Container';
import ForgottenPassword from 'Screens/ForgottenPassword/Container';
import Dashboard from 'Screens/Dashboard/Container';
import ChangePassword from 'Screens/ChangePassword/Container';
import ActivateUserPage from 'Screens/ActivateUserPage/Container';
import { ProtectedRoute } from './index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TestsPage from 'Screens/StudiesPage/Container';
import AddStudyPage from 'Screens/AddStudyPage/Container';
import AnswersTest from 'Screens/AnswersTest/Container';
import AdministratorsPage from 'Screens/AdministratorsPage/Container';
import HomePage from 'Screens/HomePage/Container';

export const Router = () => {
  return (
    <>
      <Routes location={location} key={location.pathname}>
        <Route path={'/'} element={<LoginPage />} />
        <Route path={ROUTES.HOME_PAGE} element={<HomePage />} />
        <Route
          path={ROUTES.DASHBOARD}
          element={
            <ProtectedRoute routeFor={USER_ROLES.ADMIN} redirectPath={ROUTES.LOGIN}>
              <Dashboard />
            </ProtectedRoute>
          }>
          <Route
            path={ROUTES.STUDIES}
            element={
              <ProtectedRoute routeFor={USER_ROLES.ADMIN} redirectPath={ROUTES.LOGIN}>
                <TestsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.ADD_STUDY}
            element={
              <ProtectedRoute routeFor={USER_ROLES.ADMIN} redirectPath={ROUTES.LOGIN}>
                <AddStudyPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.EDIT_STUDY}
            element={
              <ProtectedRoute routeFor={USER_ROLES.ADMIN} redirectPath={ROUTES.LOGIN}>
                <AddStudyPage />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.ANSWERS_STUDY}
            element={
              <ProtectedRoute routeFor={USER_ROLES.ADMIN} redirectPath={ROUTES.LOGIN}>
                <AnswersTest />
              </ProtectedRoute>
            }
          />
          <Route
            path={ROUTES.USERS}
            element={
              <ProtectedRoute routeFor={USER_ROLES.ADMIN} redirectPath={ROUTES.LOGIN}>
                <AdministratorsPage />
              </ProtectedRoute>
            }
          />
        </Route>
        <Route path={ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={ROUTES.FORGOTTEN_PASSWORD} element={<ForgottenPassword />} />
        <Route path={ROUTES.CHANGE_PASSWORD} element={<ChangePassword />} />
        <Route path={ROUTES.ACTIVATE_USER_PAGE} element={<ActivateUserPage />} />
      </Routes>
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};
