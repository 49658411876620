import { SVG } from 'Shared/components';
import Select from 'react-select';
import { FieldProps } from 'formik';
import { useEffect, useState } from 'react';

export interface SelectOption {
  value: string;
  label: string;
  svgType?: string;
}

interface SelectProps {
  label: string;
  options: SelectOption[];
  placeholder: string;
  name: string;
  onBlur?: any;
  fieldValue?: any;

  isMulti?: boolean;
  svgType?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  initialValue?: SelectOption;
  addMore?: boolean;
  addMoreOnClick?: () => void;
  onInputChange?: (value) => void;
  isSearchable?: boolean;
  openMenuOnClick?: boolean;
  arrayName?: string;
  index?: number;

  //formik
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: any;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  editUser: (data) => void;
  onFocus: any;
}

function SelectComponent({
  field,
  form,
  errors,
  label,
  className,
  disabled,
  name,
  options,
  placeholder,
  required,
  isMulti,
  initialValue,
  addMore,
  onInputChange,
  isSearchable = false,
  addMoreOnClick,
  openMenuOnClick,
  onBlur,
  fieldValue,
  onChange,
  arrayName,
  index,
}: SelectProps & FieldProps) {
  const [value, setValue] = useState([]);
  const handleChange = (value) => {
    if (isMulti) {
      form.setFieldValue(field.name, value);
    } else {
      form.setFieldValue(field.name, value.value);
    }
  };

  useEffect(() => {
    setValue(options.filter((item) => item.value === fieldValue));
  }, [fieldValue]);

  useEffect(() => {
    const tempArray = [];
    if (isMulti && Array.isArray(value)) {
      field.value?.map((item) => {
        tempArray.push(item.value);
      });
    } else {
      if (!field.value) {
        if (initialValue) {
          tempArray.push(initialValue.value);
        } else {
          tempArray.push(fieldValue);
        }
      } else {
        tempArray.push(field.value);
      }
    }
    setValue(options.filter((option) => tempArray.includes(option.value)));
  }, [field.value]);

  const formTouched = (name) => {
    let validate;
    if (arrayName) {
      const propertyName = name.split('_')[0];
      form.touched &&
        form.touched[arrayName] &&
        form.touched[arrayName].map((item) => {
          validate = item[propertyName];
        });
    } else {
      validate = form.touched[name];
    }
    return validate;
  };

  const errorField = (name) => {
    let validate;
    if (arrayName) {
      const propertyName = name.split('_')[0];
      validate = errors[arrayName] && errors[arrayName][index] && errors[arrayName][index][propertyName];
    } else {
      validate = errors[field.name];
    }
    return validate;
  };

  return (
    <div className="select__wrapper">
      <>
        <label className="select__label" htmlFor={field.name}>
          {label} {!required && <span className="select__required">Opcjonalne</span>}
          {addMore && !!field.value ? (
            <span className="select__add-more" onClick={addMoreOnClick}>
              + dodaj kolejny
            </span>
          ) : null}
        </label>
        <Select
          {...field}
          onBlur={() => onBlur && onBlur(field.value)}
          options={options}
          placeholder={placeholder}
          isDisabled={disabled}
          name={name}
          className={`select ${className ? className : ''} `}
          isClearable={false}
          classNamePrefix={'select'}
          onChange={(e) => {
            handleChange(e);
            onChange(e);
          }}
          isMenuOpen={false}
          value={value}
          isMulti={isMulti}
          openMenuOnClick={openMenuOnClick}
          defaultValue={initialValue}
          noOptionsMessage={() => 'Brak wyników'}
          onInputChange={onInputChange}
          isSearchable={isSearchable}
          filterOption={(option: any, input) => option.data.label.toLowerCase().includes(input.toLowerCase())}
          getOptionLabel={(props) => {
            const { label, svgType }: any = props;
            return (
              <div className="select__option-container">
                {svgType && <SVG type={svgType} />}
                <span>{label}</span>
              </div>
            ) as unknown as string;
          }}
        />
        {errors && form.touched && <div className="input__error">{formTouched(field.name) && <span>{errorField(field.name)}</span>}</div>}
      </>
    </div>
  );
}

export default SelectComponent;
