import { useState } from 'react';
import { TopBar, Button } from 'Shared/components';
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

type FirstPageType = {
  content: any;
  nextScreen: any;
};

const contentType = {
  agreement: 'agreement',
  text: 'text',
  file: 'file',
};

const FirstPage = ({ content, nextScreen }: FirstPageType) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [isValidationAgreement, setIsValidationAgreement] = useState(false);
  const contentStep = [];
  content.map((item, index) => {
    contentStep.push(index);
  });
  const [step, setStep] = useState(0);
  const handleIncrementStep = async () => {
    const isAgreementStep = content[step].content.some((contentItem) => contentItem.type === contentType.agreement);

    if (isAgreementStep) {
      const agreementCheckboxes = content[step].content.filter((contentItem) => contentItem.type === contentType.agreement);
      const allAgreementsSelected = agreementCheckboxes.every((checkbox) => selectedCheckboxes.includes(checkbox.uuid));

      if (!allAgreementsSelected) {
        setIsValidationAgreement(true);
        return;
      } else {
        setIsValidationAgreement(false);
      }
    }
    if (contentStep.length - 1 === step) {
      nextScreen();
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 100);
    } else {
      setStep(step + 1);
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }, 100);
    }
  };

  const handleCheckboxChange = (uuid) => {
    if (selectedCheckboxes.includes(uuid)) {
      setSelectedCheckboxes(selectedCheckboxes.filter((item) => item !== uuid));
    } else {
      setSelectedCheckboxes([...selectedCheckboxes, uuid]);
    }
  };

  const language = sessionStorage.getItem('language');
  const isEnLanguage = language === 'EN';

  return (
    <div className="page-wrapper">
      <TopBar className="top-bar--first-page" />
      <div className="first-page">
        <div className="first-page__content">
          <div className="first-page__content-screen">
            {content[step].content.map((contentItem) => {
              const htmlContent = contentItem.text;
              if (htmlContent) {
                const contentState = htmlContent && ContentState.createFromBlockArray(convertFromHTML(htmlContent));
                const rawContent = convertToRaw(contentState);
                if (contentItem.type === contentType.agreement) {
                  const isCheckboxSelected = selectedCheckboxes.includes(contentItem.uuid);
                  const isValidate = !isCheckboxSelected && isValidationAgreement;
                  return (
                    <div className="checkbox" key={contentItem.uuid}>
                      <label className="checkbox__option">
                        <input
                          className="checkbox__input"
                          type="checkbox"
                          checked={isCheckboxSelected}
                          onChange={() => handleCheckboxChange(contentItem.uuid)}
                        />
                        <div
                          className="checkbox__title-wrapper"
                          key={contentItem.uuid}
                          dangerouslySetInnerHTML={{ __html: draftToHtml(rawContent) }}
                        />
                      </label>
                      {isValidate && <div className="input__error"> {isEnLanguage ? 'Consent required' : 'Wymagana zgoda'} </div>}
                    </div>
                  );
                } else {
                  return <div className="first-page__text" key={contentItem.uuid} dangerouslySetInnerHTML={{ __html: draftToHtml(rawContent) }} />;
                }
              } else if (contentItem.type === contentType.file) {
                return (
                  <div className="first-page__image" key={contentItem.uuid}>
                    <img src={contentItem.src} alt="" />
                  </div>
                );
              }
            })}
          </div>
          <div className="first-page__buttons">
            {step >= 1 && <Button label={isEnLanguage ? 'Back' : 'Wróć'} stroke onClick={() => setStep(step - 1)} />}
            <Button
              className="first-page__buttons--right"
              label={isEnLanguage ? 'Next' : 'Dalej'}
              type="submit"
              onClick={() => handleIncrementStep()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstPage;
