import { useState } from 'react';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { SVG } from 'Shared/components';
import { useNavigate } from 'react-router';
import { ChangePasswordModal } from '../index';
import { MobileMenu } from 'Screens/Dashboard/components';

type DashboardTopBarType = {
  logout: () => void;
  changeCurrentAdminPassword: (currentPassword, password) => void;
};

const DashboardTopBar = ({ logout, changeCurrentAdminPassword }: DashboardTopBarType) => {
  const isMobile = window.innerWidth < 1024;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleLogoutUser = async () => {
    await logout();
    sessionStorage.removeItem('userRole');
    navigate({ pathname: ROUTES.LOGIN });
    document.location.reload();
  };

  const handleOpenModal = () => {
    setIsMenuOpen(false);
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <ChangePasswordModal
        changeCurrentAdminPassword={changeCurrentAdminPassword}
        isModalOpen={isModalOpen}
        isModalClose={() => setIsModalOpen(false)}
      />
      {!isMobile ? (
        <div className="dashboard-top-bar">
          <div className="dashboard-top-bar__logo">{/* <SVG type={SVG_TYPE.GOALMAPP_WITHOUT_PADDING} /> */}</div>
          <div className="dashboard-top-bar-menu__wrapper">
            <>
              <div className="dashboard-top-bar__user" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                Admin <SVG type={SVG_TYPE.CHEVRONE} />
              </div>
              <div className={`dashboard-top-bar-menu__content ${isMenuOpen ? 'dashboard-top-bar-menu__content--show' : ''}`}>
                <div className="dashboard-top-bar-menu__item" onClick={() => handleOpenModal()}>
                  Zmień hasło
                </div>
                <div className="dashboard-top-bar-menu__item dashboard-top-bar-menu__item--logout" onClick={() => handleLogoutUser()}>
                  Wyloguj się
                </div>
              </div>
            </>
          </div>
        </div>
      ) : (
        <>
          <MobileMenu handleLogoutUser={handleLogoutUser} handleOpenModal={handleOpenModal} />
        </>
      )}
    </>
  );
};

export default DashboardTopBar;
