import { TopBar, SVG, Button } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { useEffect, useState } from 'react';
import { MainType } from './Container';
import { BoardTarget, Loader } from 'Shared/components';
import { useLocation } from 'react-router';
import { GetLost, FirstPage, LastPage } from './components';

const loadingScreen = () => {
  return (
    <div className="home-page__loader">
      <Loader width="5" height="5" />
    </div>
  );
};

const screens = {
  getLost: 'getLost',
  firstPage: 'firstPage',
  map: 'map',
  lastPage: 'lastPage',
  finished: 'finished',
};

const HomePage = (props: MainType) => {
  const { actions } = props;
  const { getStudyByLink, sendStudyForm, getUserIsActive, addFileMap } = actions;
  const location = useLocation();
  const pathname = location.pathname;
  const link = pathname.slice(1);
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const language = sessionStorage.getItem('language');
  const isEnLanguage = language === 'EN';

  const [isLoading, setIsLoading] = useState(true);
  const [study, setStudy] = useState(null);
  const [screen, setScreen] = useState('');

  const setScreenLastPage = () => {
    setScreen(screens.lastPage);
    sessionStorage.setItem('studyStep', JSON.stringify({ ...study, step: screens.lastPage }));
  };
  const setScreenMap = () => {
    setScreen(screens.map);
    sessionStorage.setItem('studyStep', JSON.stringify({ ...study, step: screens.map }));
  };

  const initialStorage = JSON.parse(sessionStorage.getItem('studyStep'));
  const studyStorage = initialStorage;

  useEffect(() => {
    if (study?.userUuid) {
      getUserIsActive(study.userUuid)
        .then((response) => {
          if (response) {
            const interval = setInterval(() => {
              getUserIsActive(study.userUuid);
            }, 10000);
            return () => {
              clearInterval(interval);
            };
          }
        })
        .catch((error) => {
          error;
        });
    }
  }, [study?.userUuid]);

  useEffect(() => {
    setIsLoading(true);
    if (studyStorage?.link === link && studyStorage.userUuid) {
      setScreen(studyStorage.step);
      setStudy(studyStorage);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } else {
      if (!study) {
        getStudyByLink(link, id)
          .then((response) => {
            sessionStorage.setItem('language', response.language);
            sessionStorage.setItem('studyStep', JSON.stringify({ ...response, step: screens.firstPage, link, id }));
            sessionStorage.removeItem('initialNodes');
            sessionStorage.removeItem('initialEdges');
            if (response.userUuid) {
              setStudy({ ...response, step: screens.firstPage, link, id });
              setScreen(screens.firstPage);
            } else {
              setScreen(screens.getLost);
            }

            setTimeout(() => {
              setIsLoading(false);
            }, 1000);
          })
          .catch((error) => {
            if (error) {
              setTimeout(() => {
                setIsLoading(false);
              }, 1000);
            }
          });
      }
    }
  }, []);

  const handleRedirectAfterFinish = (link) => {
    if (link) {
      if (!link.startsWith('http://') && !link.startsWith('https://')) {
        window.location.href = 'https://' + link;
      } else {
        window.location.href = link;
      }
    }
  };

  const renderSteps = () => {
    switch (screen) {
      case screens.getLost: {
        return <GetLost />;
      }
      case screens.firstPage: {
        return <FirstPage nextScreen={() => setScreenMap()} content={study.firstPage} />;
      }
      case screens.map: {
        return (
          <BoardTarget
            addFileMap={addFileMap}
            setScreenLastPage={setScreenLastPage}
            study={study}
            sendStudyForm={sendStudyForm}
            parameters={study.parameters}
          />
        );
      }
      case screens.lastPage: {
        return <LastPage setScreen={setScreen} userUuid={study.userUuid} sendStudyForm={sendStudyForm} content={study.lastPage} />;
      }
      case screens.finished: {
        sessionStorage.removeItem('language');
        return (
          <div className="page-wrapper">
            <TopBar addFileMap={addFileMap} />
            <div className="get-lost">
              <div className="get-lost__content">
                <SVG type={SVG_TYPE.STUDY_FINISHED} />
                <div className="get-lost__header">{isEnLanguage ? 'That’s all' : 'To już wszystko!'}</div>
                <div className="get-lost__paragraph">
                  {isEnLanguage ? 'Thank you for taking part in the study' : 'Dziękujemy za udział w badaniu.'}
                </div>
                {study?.finishLink && (
                  <Button
                    className="finish-link"
                    label={isEnLanguage ? 'Go back to the system' : 'Powrót do systemu'}
                    onClick={() => handleRedirectAfterFinish(study?.finishLink)}
                  />
                )}
              </div>
            </div>
          </div>
        );
      }
    }
  };

  return <>{isLoading ? loadingScreen() : renderSteps()}</>;
};

export default HomePage;
