import { useState, useEffect } from 'react';
import { Button, SVG, Badge, Pagination } from 'Shared/components';
import { SVG_TYPE, ROUTES } from 'Shared/enums';
import { useNavigate } from 'react-router';
import { MainType } from './Container';
import { useLocation, generatePath } from 'react-router-dom';
import moment from 'moment';
import { DeleteMapModal } from './components';

const AnswersTest = (props: MainType) => {
  const { actions, studyAnswersList } = props;
  const { getStudyAnswerList, getMapReport, deleteMap, getGroupReport } = actions;
  const location = useLocation();
  const [showDetails, setShowDetails] = useState(false);
  const [isDeleteMapModalOpen, setIsDeleteMapModalOpen] = useState(false);
  const isMobile = window.innerWidth < 1024;
  const showButtonName = window.innerWidth > 1200;
  const navigate = useNavigate();
  const studyStorage = JSON.parse(sessionStorage.getItem('study'));

  const showBadge = (item) => {
    if (item.status === 'incomplete') {
      return <Badge label="Niekompletne" status="light" />;
    } else {
      if (item.status === 'complete') {
        return <Badge label="Kompletne" status="bold" />;
      } else {
        return <Badge label="Ukończona mapa" status="medium" />;
      }
    }
  };
  const { pathname } = location;
  const pathParts = pathname.split('/');
  const link = pathParts[pathParts.length - 2];
  const page = parseInt(pathParts[pathParts.length - 1]);
  const handleGetStudyAnswerList = () => {
    getStudyAnswerList({ link, page });
  };
  useEffect(() => {
    handleGetStudyAnswerList();
  }, [location]);

  const changePage = (page) => {
    setShowDetails(null);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setTimeout(() => {
      navigate({ pathname: generatePath(ROUTES.ANSWERS_STUDY, { study: link, page }) });
    }, 200);
  };

  const handleShowDetails = (idx) => {
    if (idx === showDetails) {
      setShowDetails(null);
    } else {
      setShowDetails(idx);
    }
  };

  const handleDownloadReport = async (mapUuid) => {
    await getMapReport(mapUuid);
  };

  const handleDownloadGroupReport = async () => {
    await getGroupReport(link);
  };

  const handleOpenDeleteModal = (item) => {
    setIsDeleteMapModalOpen(item);
  };
  const handleDownloadMap = async (item, id) => {
    const response = await fetch(item);
    const blob = await response.blob();

    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${id}_mapa.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <>
      <DeleteMapModal
        deleteMap={deleteMap}
        handleGetStudyAnswerList={handleGetStudyAnswerList}
        uuid={isDeleteMapModalOpen}
        close={() => setIsDeleteMapModalOpen(false)}
      />
      <div className="dashboard-content">
        <div className="dashboard-header answers-test__heading">
          <div className="dashboard-heading">
            <p
              className="dashboard-heading-icon"
              onClick={() => navigate({ pathname: generatePath(ROUTES.STUDIES, { page: studyStorage.page || 1 }) })}>
              <SVG type={SVG_TYPE.ARROW_BACK} />
            </p>
            {studyStorage.name}
          </div>
          <Button
            className="answers-test__heading-button"
            label="Raport zbiorowy"
            iconClass="icon-right"
            svg_type={SVG_TYPE.DOWNLOAD_FILE}
            onClick={() => handleDownloadGroupReport()}
          />
        </div>
        {isMobile ? (
          <>
            <div className="test-mobile-list">
              {studyAnswersList.data &&
                studyAnswersList?.data.length > 0 &&
                studyAnswersList.data.map((item, idx) => {
                  return (
                    <div key={item.uuid} className="test-mobile-list__item">
                      <div className="test-mobile-list__item-top">
                        <div className="test-mobile-list__item-onClick" onClick={() => handleShowDetails(idx)} />
                        <div className="test-mobile-list__item-name">#{item.id}</div>
                        <div className="test-mobile-list__item-name">{item.timeInStudy}</div>
                        <div className="test-mobile-list__item-status">{showBadge({ status: item.status })}</div>
                      </div>
                      <div className={`test-mobile-list__item-bottom ${showDetails === idx ? 'test-mobile-list__item-bottom--show' : ''}`}>
                        {item.status !== 'incomplete' && (
                          <Button
                            label="Raport użytkownika"
                            onClick={() => handleDownloadReport(item.uuid)}
                            iconClass="icon-right"
                            stroke
                            svg_type={SVG_TYPE.DOWNLOAD_FILE}
                          />
                        )}
                        {item.mapUrl && (
                          <Button
                            iconClass="icon-right"
                            label="Mapa"
                            onClick={() => handleOpenDeleteModal(item.mapUrl)}
                            stroke
                            svg_type={SVG_TYPE.DOWNLOAD_FILE}
                          />
                        )}
                        <Button label="" onClick={() => handleOpenDeleteModal(item.uuid)} stroke svg_type={SVG_TYPE.BIN} />
                        <div className="test-mobile-list__item-bottom--data">
                          <p>Data utworzenia:</p>
                          <span>{moment(item.createdAt).format('DD/MM/YYYY')}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        ) : (
          <>
            <div className="answers-test-list__table">
              <div className="answers-test-list__thead">
                <div className="answers-test-list__table-list">
                  <p>ID</p>
                  <p>Data utworzenia</p>
                  <p>Czas trwania</p>
                  <p className="answers-test-list__table-list--number">Status</p>
                  <p></p>
                </div>
              </div>
              {studyAnswersList.data &&
                studyAnswersList?.data.length > 0 &&
                studyAnswersList.data.map((item) => {
                  return (
                    <div key={item.uuid} className="answers-test-list__tbody">
                      <div className="answers-test-list__tbody-list">
                        <p className="answers-test-list__tbody-item">#{item.id}</p>
                        <p className="answers-test-list__tbody-item">{moment(item.createdAt).format('DD/MM/YYYY')}</p>
                        <p className="answers-test-list__tbody-item">{item.timeInStudy}</p>
                        <p className="answers-test-list__tbody-item">{showBadge({ status: item.status })}</p>
                        <p className="answers-test-list__tbody-item answers-test-list__tbody-item--button">
                          {item.status !== 'incomplete' && (
                            <Button
                              label={`${showButtonName ? 'Raport użytkownika' : 'Raport'}`}
                              onClick={() => handleDownloadReport(item.uuid)}
                              iconClass="icon-right"
                              stroke
                              svg_type={SVG_TYPE.DOWNLOAD_FILE}
                            />
                          )}
                          {item.mapUrl && (
                            <Button label="Mapa" onClick={() => handleDownloadMap(item.mapUrl, item.id)} stroke svg_type={SVG_TYPE.DOWNLOAD_FILE} />
                          )}
                          <Button label=" " onClick={() => handleOpenDeleteModal(item.uuid)} stroke svg_type={SVG_TYPE.BIN} />
                        </p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        )}

        {studyAnswersList && studyAnswersList?.meta?.last_page > 1 ? (
          <Pagination
            className="administrators-list__pagination"
            totalPages={studyAnswersList.meta.last_page}
            currentPage={studyAnswersList.meta.current_page}
            onPageChange={(page) => changePage(page)}
          />
        ) : null}
      </div>
    </>
  );
};

export default AnswersTest;
