import * as UserModule from '../../../Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';
import { toast } from 'react-toastify';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
const init = () => ({
  type: User.EDIT_USER_INIT,
});

const success = (data) => ({
  type: User.EDIT_USER_SUCCESS,
  payload: {
    data,
  },
});

const failure = () => ({
  type: User.EDIT_USER_FAILURE,
});

const editUser = (uuid, form) => async (dispatch) => {
  dispatch(init());
  const id = toast.loading('Trwa edycja użytkownika...');
  try {
    const { data } = await UserModule.Connector.editUser(uuid, form);
    toast.update(id, {
      render: 'Pomyślnie edytowano użytkownika',
      type: toast.TYPE.SUCCESS,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.CIRCLE_GREEN} type={SVG_TYPE.CHECKED} />,
    });
    dispatch(success(data.user));
  } catch (err: any) {
    dispatch(failure());
    toast.update(id, {
      render: 'Wystąpił błąd podczas edytowania użytkownika, sprawdź później',
      type: toast.TYPE.ERROR,
      isLoading: false,
      closeButton: true,
      autoClose: 3000,
      icon: <SVG variant={SVG_TYPE.WARNING} type={SVG_TYPE.CHECKED} />,
    });
  }
};

export default editUser;
