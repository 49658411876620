import { useState } from 'react';
import { LoginContent, Input, Button } from 'Shared/components';
import { VALIDATION_MESSAGES } from 'Shared/enums';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { MainType } from './Container';

const validation = () =>
  Yup.object().shape({
    email: Yup.string().required(VALIDATION_MESSAGES.REQUIRED).email(VALIDATION_MESSAGES.EMAIL),
  });

const ForgottenPassword = (props: MainType) => {
  const [resendMail, setResendMail] = useState('');
  const { actions } = props;
  const { sendPasswordResetLink } = actions;
  const [isSend, setIsSend] = useState(false);

  const handleSendMail = async ({ email }) => {
    const response = await sendPasswordResetLink(email);
    if (response) {
      setResendMail(email);
      localStorage.setItem('resendMail', email);
      setIsSend(true);
    }
  };

  return (
    <>
      {!isSend ? (
        <LoginContent
          goBack
          goBackFunc={() => window.history.back()}
          heading="Nie pamiętasz hasła?"
          descript="Wprowadź swój adres e-mail, aby otrzymać instrukcje dotyczące resetowania hasła.">
          <Formik
            validationSchema={validation}
            initialValues={{ email: '' }}
            validateOnBlur={false}
            enableReinitialize
            onSubmit={async (value) => handleSendMail(value)}>
            {({ handleSubmit, errors }) => (
              <form onSubmit={handleSubmit}>
                <Field
                  label="Email"
                  name="email"
                  placeholder=""
                  required
                  component={Input}
                  treshold={0}
                  maxLength={32}
                  errors={errors}
                />
                <div className="login-content__button">
                  <Button label="Wyślij" type="submit" />
                </div>
              </form>
            )}
          </Formik>
        </LoginContent>
      ) : (
        <LoginContent
          goBack
          goBackFunc={() => setIsSend(false)}
          heading="Wysłano link do resetu hasła"
          descript="Jeśli konto z tym adresem istnieje, to link do resetu hasła powinien już czekać na skrzynce. Kliknij w niego i utwórz nowe hasło.">
          <div className="login-page__email-did-not-arrive">Mail nie dotarł?</div>
          <div className="login-content__button">
            <Button label="Wyślij ponownie" type="submit" onClick={() => handleSendMail({ email: resendMail })} />
          </div>
        </LoginContent>
      )}
    </>
  );
};

export default ForgottenPassword;
