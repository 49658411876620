import { Modal, Button } from 'Shared/components';

type SuspendUserModalType = {
  suspendUser: (uuid, action) => void;
  onClose: () => void;
  userUuid: boolean;
  action: string;
};

const SuspendUserModal = ({ suspendUser, userUuid, onClose, action }: SuspendUserModalType) => {
  const isSuspend = action === 'suspend';

  const handleSuspendUser = async (uuid, action) => {
    await suspendUser(uuid, action);
    onClose();
  };

  return (
    <Modal className="add-administrator-modal" isOpen={userUuid} onClose={() => onClose()}>
      <div className="modal-heading">{isSuspend ? 'Zawieszanie' : 'Przywracanie'} administratora</div>
      <div className="suspend-user-modal__descript">Czy na pewno chcesz {isSuspend ? 'zawieśić' : 'przywrócić'} tego administratora?</div>
      <div className="suspend-user-modal__buttons">
        <Button label="Anuluj" stroke onClick={() => onClose()} />
        <Button label="Potwierdź" onClick={() => handleSuspendUser(userUuid, action)} />
      </div>
    </Modal>
  );
};

export default SuspendUserModal;
