import { useEffect, useState } from 'react';
import { TopBar, Textarea, Slider, Button } from 'Shared/components';
import { ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Field, Formik } from 'formik';
import { VALIDATION_MESSAGES } from 'Shared/enums';
import * as Yup from 'yup';

type LastPageType = {
  content: any;
  sendStudyForm: any;
  userUuid: any;
  setScreen: any;
};

const LastPage = ({ sendStudyForm, userUuid, setScreen, content: { content } }: LastPageType) => {
  const [initialValues, setInitialValues] = useState({});
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [validationSchema, setValidationSchema] = useState({});
  const language = sessionStorage.getItem('language');
  const isEnLanguage = language === 'EN';

  useEffect(() => {
    const initialValuesUpdated = {};
    const validationSchemaUpdated = {};

    content.forEach((item) => {
      if (item.type != 'text') {
        initialValuesUpdated[item.uuid] = '';
        validationSchemaUpdated[item.uuid] = Yup.string().required(isEnLanguage ? VALIDATION_MESSAGES.REQUIRED_EN : VALIDATION_MESSAGES.REQUIRED);
      }
    });

    setInitialValues(initialValuesUpdated);
    setValidationSchema(validationSchemaUpdated);
  }, [content]);

  const handleOnSubmit = async (values) => {
    setIsButtonLoading(true);
    const arrayResult = [];
    for (const key in values) {
      const uuid = key;
      const studyItem = content.find((item) => item.uuid === uuid);
      if (studyItem.questionType === 'open') {
        arrayResult.push({ uuid: studyItem.uuid, type: studyItem.questionType, answer: values[key] });
      } else {
        arrayResult.push({ uuid: studyItem.uuid, type: studyItem.questionType, scaleValue: values[key] });
      }
    }
    const response = await sendStudyForm({ answers: arrayResult, userUuid: userUuid });
    if (response) {
      sessionStorage.removeItem('studyStep');
      sessionStorage.removeItem('initialNodes');
      sessionStorage.removeItem('initialEdges');
      sessionStorage.removeItem('imgMap');
      setScreen('finished');
      setIsButtonLoading(false);
    }
  };

  return (
    <div className="page-wrapper">
      <TopBar />
      <div className="last-page">
        <div className="last-page__content">
          <img className="last-page__canvas" src={sessionStorage.getItem('imgMap')} />
          {initialValues && validationSchema && (
            <Formik
              validationSchema={Yup.object(validationSchema)}
              initialValues={initialValues}
              validateOnBlur={false}
              enableReinitialize
              onSubmit={async (values) => handleOnSubmit(values)}>
              {({ handleSubmit, errors, setFieldValue }) => {
                let questionNumber = 0;
                return (
                  <form onSubmit={handleSubmit}>
                    {content.map((item) => {
                      const htmlContent = item.text;
                      if (item.type === 'question') {
                        questionNumber++;
                      }
                      if (htmlContent && item.type === 'text') {
                        const contentState = ContentState.createFromBlockArray(convertFromHTML(htmlContent));
                        const rawContent = convertToRaw(contentState);
                        return <div className="last-page__text" key={item.uuid} dangerouslySetInnerHTML={{ __html: draftToHtml(rawContent) }} />;
                      } else if (item.questionType === 'open') {
                        return (
                          <Field
                            key={item.uuid}
                            label={`${questionNumber}. ${item.text}`}
                            name={item.uuid}
                            placeholder=""
                            component={Textarea}
                            errors={errors}
                            required
                          />
                        );
                      } else if (item.questionType === 'scale') {
                        const marks = {};
                        for (let i = 0; i < item.titles.length; i++) {
                          const isLeft = i === 0;
                          const isRight = i === item.titles.length - 1;
                          if (item.titles[i] !== '') {
                            marks[i + 1] = {
                              label: (
                                <div className={`slider__marks ${isLeft ? 'slider__marks--left' : ''} ${isRight ? 'slider__marks--right' : ''}`}>
                                  <span>{i + 1}</span>
                                  <span>{item.titles[i]}</span>
                                </div>
                              ),
                            };
                          } else {
                            marks[i + 1] = (i + 1).toString();
                          }
                        }

                        return (
                          <div key={item.key}>
                            <Field
                              key={item.key}
                              label={`${questionNumber}. ${item.text}`}
                              name={item.uuid}
                              component={Slider}
                              errors={errors}
                              scaleLevel={parseInt(item.scaleLevel)}
                              onChange={(e) => setFieldValue(item.uuid, e)}
                              marks={marks}
                              required
                            />
                          </div>
                        );
                      }
                    })}
                    <div className="last-page__buttons">
                      <Button loader={isButtonLoading} type="submit" label={isEnLanguage ? 'Send' : 'Wyślij'} onClick={() => handleSubmit()} />
                    </div>
                  </form>
                );
              }}
            </Formik>
          )}
        </div>
      </div>
    </div>
  );
};

export default LastPage;
