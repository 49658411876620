import { useState } from 'react';
import { Button, SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';
import { EndMapModal } from './components';

type TopBarType = {
  nodes?: any;
  setNodes?: any;
  selectedNode?: any;
  setSelectedNode?: any;
  showButtons?: any;
  sendStudyForm?: any;
  study?: any;
  setScreenLastPage?: any;
  initialValues?: any;
  edges?: any;
  formValues?: any;
  setIsMissingParameter?: any;
  setInitialValues?: any;
  className?: any;
  addFileMap?: any;
};

const TopBar = ({
  setSelectedNode,
  setIsMissingParameter,
  nodes,
  setNodes,
  showButtons,
  sendStudyForm,
  study,
  setScreenLastPage,
  edges,
  formValues,
  setInitialValues,
  className,
  addFileMap,
}: TopBarType) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const language = sessionStorage.getItem('language');
  const isEnLanguage = language === 'EN';

  const access = () => {
    const submit = document.getElementsByClassName('submitSidebar');
    if (submit.length > 0) {
      const submitButton = submit[0] as HTMLButtonElement;
      if (submitButton) {
        submitButton.click();
      }
    }
    let isEmpty = true;
    let isDefaultEdge = true;
    for (const key in formValues) {
      isEmpty = false;
      key;
      break;
    }
    function isObjectNotEmpty() {
      if (!formValues.hasOwnProperty('descript')) {
        return true;
      }
      for (const key in formValues) {
        if (key !== 'descript' && (formValues[key] === '' || formValues[key] === '0')) {
          return false;
        }
      }
      return true;
    }
    if (edges.some((item) => item.style.stroke === 'black')) {
      isDefaultEdge = false;
    }
    if ((isEmpty || isObjectNotEmpty()) && isDefaultEdge) {
      return true;
    } else {
      return false;
    }
  };

  const handleAddTarget = () => {
    const createNewTarget = () => {
      const updatedNodes = nodes.map((item) => {
        if (item.className.includes('node-selected-element')) {
          const classes = item.className.split(' ');
          const updatedClasses = classes.filter((className) => className !== 'node-selected-element');
          item.className = updatedClasses.join(' ');
        }
        return item;
      });
      setNodes(updatedNodes);
      const newNodeFocus = document.getElementById('focused-node');
      setTimeout(() => {
        document.getElementById('add-new-target').click();
      }, 100);
      setTimeout(() => {
        newNodeFocus.click();
        const sidebarTargets = document.getElementsByClassName('sidebar-target--active');
        if (sidebarTargets.length > 0) {
          setTimeout(() => {
            sidebarTargets[0].scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }, 100);
        }
      }, 300);
    };

    if (access()) {
      setTimeout(() => {
        setIsMissingParameter(false);
        createNewTarget();
        setInitialValues({});
      }, 400);
    } else {
      setIsMissingParameter(true);
    }
  };

  const handleEndMap = async (mapUuid) => {
    setSelectedNode(null);
    const updatedNodes = nodes.map((item) => {
      if (item.className.includes('node-selected-element')) {
        const classes = item.className.split(' ');
        const updatedClasses = classes.filter((className) => className !== 'node-selected-element');
        item.className = updatedClasses.join(' ');
      }
      return item;
    });
    const response = await sendStudyForm({ initialEdges: edges, initialNodes: updatedNodes, userUuid: study.userUuid, mapFilesUuid: mapUuid });
    setIsLoadingButton(false);
    if (response) {
      // setNodes(updatedNodes);
      setScreenLastPage();
      setIsOpenModal(false);
    }
  };

  const handleOpenEndModal = () => {
    if (access()) {
      setIsOpenModal(true);
    }
  };

  return (
    <>
      <EndMapModal
        setIsLoadingButton={setIsLoadingButton}
        isLoadingButton={isLoadingButton}
        addFileMap={addFileMap}
        nodes={nodes}
        close={() => setIsOpenModal(false)}
        isOpenModal={isOpenModal}
        handleEndMap={handleEndMap}
      />
      <div className={`top-bar ${className}`}>
        <div className="top-bar--left">
          <div className="top-bar-application-name">
            <SVG type={SVG_TYPE.GOALMAPP_WITHOUT_PADDING} />
          </div>
        </div>
        {showButtons && (
          <div className="top-bar--right">
            {nodes.length >= 2 && <Button stroke label={isEnLanguage ? 'Finish' : 'Zakończ'} onClick={() => handleOpenEndModal()} />}
            <Button className="add-target" label={isEnLanguage ? 'Add goal +' : 'Dodaj cel +'} onClick={() => handleAddTarget()} />
          </div>
        )}
      </div>
    </>
  );
};

export default TopBar;
