import { Modal, Button } from 'Shared/components';

type DeleteStudyType = {
  test: any;
  onClose: () => void;
  handleDeleteStudy: (data) => void;
};
const localStorageForm = JSON.parse(sessionStorage.getItem('formTest'));

const DeleteStudy = ({ test, onClose, handleDeleteStudy }: DeleteStudyType) => {
  const handleDeleteClick = () => {
    if (localStorageForm?.testUuid === test.uuid) {
      sessionStorage.removeItem('formTest');
    }
    handleDeleteStudy({ testUuid: test.uuid });
  };
  return (
    <>
      <Modal className="delete-test-modal" isOpen={test} onClose={() => onClose()}>
        <div className="modal-heading">Usuwanie badania</div>
        <div className="delete-test-modal__descript">
          Czy na pewno chcesz usunąć badanie <span>{test.name}</span>?
        </div>
        <div className="delete-test-modal__buttons">
          <Button label="Anuluj" stroke onClick={() => onClose()} />
          <Button label="Potwierdź" onClick={() => handleDeleteClick()} />
        </div>
      </Modal>
    </>
  );
};

export default DeleteStudy;
