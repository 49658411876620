import { useState, useEffect } from 'react';
import { FieldProps } from 'formik';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';

interface TextEditorProps {
  label: string;
  name: string;
  className?: string;
  placeholder?: string;
  id?: string;
  value: string;

  //formik
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (value) => void;
  handleOnChange?: any;
  isOnBlurAction?: boolean;
  onFocus: any;
  onBlur?: any;
  arrayName?: string;
  index?: string;
}

const TextEditor = ({ id, label, className = '', name, form, errors = {}, field, onBlur, value, arrayName, index }: TextEditorProps & FieldProps) => {
  const [editor, setEditor] = useState(EditorState.createEmpty());
  const handleEditor = (e, text) => {
    if (text !== 'onBlur') {
      setEditor(e);
      const contentState = e.getCurrentContent();
      const rawContentState = convertToRaw(contentState);
      const blocks = rawContentState.blocks.map((block) => {
        let newText = block.text;
        block.inlineStyleRanges.forEach((style) => {
          const { offset, length, style: inlineStyle } = style;
          const startTag = `<span style="${getInlineStyle(inlineStyle)}">`;
          const endTag = '</span>';

          newText = newText.slice(0, offset) + startTag + newText.slice(offset, offset + length) + endTag + newText.slice(offset + length);
        });

        return {
          ...block,
          text: newText,
        };
      });

      const modifiedContentState = {
        ...rawContentState,
        blocks,
      };

      const html = draftToHtml(modifiedContentState);
      form.setFieldValue(name, html);
    } else {
      onBlur(draftToHtml(convertToRaw(editor.getCurrentContent())));
    }
  };

  const getInlineStyle = (styleName) => {
    switch (styleName) {
      case 'BOLD':
        return 'font-weight: bold;';
      case 'ITALIC':
        return 'font-style: italic;';
      case 'UNDERLINE':
        return 'font-style: italic;';
      default:
        return '';
    }
  };

  useEffect(() => {
    if (value) {
      const blocksFromHTML = convertFromHTML(value);
      const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);
      const editorState = EditorState.createWithContent(contentState);
      setEditor(editorState);
    }
  }, [value]);

  const formTouched = (name) => {
    let validate;
    if (arrayName) {
      const propertyName = name.split('_')[0];
      if (arrayName != 'lastPage') {
        form.touched &&
          form.touched[arrayName] &&
          form.touched[arrayName].map((item) => {
            validate = item[propertyName];
          });
      } else {
        form.touched &&
          form.touched[arrayName] &&
          form.touched[arrayName].content.map((item) => {
            validate = item[propertyName];
          });
      }
    } else {
      validate = form.touched[name];
    }
    return validate;
  };

  const errorField = (name) => {
    let validate;
    if (arrayName) {
      const propertyName = name.split('_')[0];
      if (arrayName != 'lastPage') {
        validate = errors[arrayName] && errors[arrayName][index] && errors[arrayName][index][propertyName];
      } else {
        validate =
          errors[arrayName] && errors[arrayName]?.content && errors[arrayName]?.content[index] && errors[arrayName]?.content[index][propertyName];
      }
    } else {
      validate = errors[field.name];
    }
    return validate;
  };

  return (
    <div id={id} className={`text-editor__wrapper ${className} ${form.touched[field.name] && errors[field.name] ? 'input__wrapper--error' : ''}`}>
      <label className="text-editor__label" htmlFor={field.name}>
        {label}
      </label>
      <Editor
        onBlur={(e) => convertToRaw(handleEditor(e, 'onBlur'))}
        // onBlur={() => onBlur(draftToHtml(convertToRaw(editor.getCurrentContent())))}
        editorState={editor}
        toolbarClassName="toolbarClassName"
        wrapperClassName="text-editor"
        editorClassName="text-editor__content"
        onEditorStateChange={(e) => handleEditor(e, 'onEditorStateChange')}
      />
      {errors && form.touched && <div className="input__error">{formTouched(field.name) && <span>{errorField(field.name)}</span>}</div>}
    </div>
  );
};

export default TextEditor;
