import { useState, useEffect } from 'react';
import { Button, Input, Select, TextEditor, DropFile, SVG, Loader } from 'Shared/components';
import { Formik, Field } from 'formik';
import { ROUTES, SVG_TYPE, VALIDATION_MESSAGES } from 'Shared/enums';
import { CustomParameterSlider, CustomLastPageSlider } from './components';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import * as Yup from 'yup';
import { MainType } from './Container';
import { useNavigate } from 'react-router';
import { generatePath, useLocation } from 'react-router-dom';

const screenType = {
  text: 'text',
  file: 'file',
  agreement: 'agreement',
  question: 'question',
};

const selectCircleOptions = [
  { value: 'circleSize', label: 'Rozmiar koła' },
  { value: 'contourSize', label: 'Rozmiar obrysu' },
  { value: 'colorSaturation', label: 'Nasycenie koloru' },
  { value: 'noGraphics', label: 'Brak' },
];

const AddStudyPage = (props: MainType) => {
  const navigate = useNavigate();
  const { actions, editStudy } = props;
  const { addStudy, getNewStudyUuid, addStudyFiles, getStudyFiles, deleteStudyFile, getStudyForEdit, editStudyForm } = actions;
  const location = useLocation();
  const pathname = location.pathname;
  const isEdit = pathname.includes('edit-study');
  const pathParts = pathname.split('/');
  const link = pathParts[pathParts.length - 1];
  const isMobile = window.innerWidth < 1024;
  const localStorageForm = JSON.parse(sessionStorage.getItem('formTest'));
  const localStorageStudiesPage = JSON.parse(sessionStorage.getItem('studiesListPage'));
  const [testUuid, setTestUuid] = useState(isEdit ? link : link === 'add-study' ? localStorageForm?.testUuid : localStorageForm?.testUuid);
  const [showButton, setShowButton] = useState(false);
  const [welcomeScreen, setWelcomeScreen] = useState(localStorageForm?.firstPage ? localStorageForm.firstPage : [{ index: 1, content: [] }]);
  const [parameterScreen, setParameterScreen] = useState(
    localStorageForm?.parameters
      ? localStorageForm.parameters
      : [{ index: 1, text: '', graphicChange: '', title: '', titles: [], scaleLevel: 5, uniqueId: Math.random().toString(36).substring(7) }],
  );
  const [initialLastPage, setInitialLastPage] = useState(null);
  const [lastPageScreen, setLastPageScreen] = useState(localStorageForm?.lastPage ? localStorageForm?.lastPage : { content: [] });
  const [step, setStep] = useState(localStorageForm?.step ? localStorageForm.step : 1);
  const [isEditNextStepError, setIsEditNextStepError] = useState(false);
  const [isEditPreviousStepError, setIsEditPreviousStepError] = useState(false);
  const [isLoadingStudyForEdit, setIsLoadingStudyForEdit] = useState(false);
  const isFirstStep = step === 1;
  const isSecondStep = step === 2;
  const isThirdStep = step === 3;
  const isFourthStep = step === 4;
  const [isValid, setIsValid] = useState(false);
  const [selectGraphicState, setSelectGraphicState] = useState(!localStorageForm?.parameters ? [''] : []);
  const graphicState = [];

  useEffect(() => {
    setTimeout(() => {
      setShowButton(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (isEdit && editStudy) {
      editStudy.parameters.map((item) => {
        graphicState.push(item.graphicChange);
      });
      setSelectGraphicState(graphicState);
    }
  }, [editStudy]);

  useEffect(() => {
    if (isEdit && editStudy) {
      setWelcomeScreen(editStudy.firstPage);
      setParameterScreen(editStudy.parameters);
      setLastPageScreen(editStudy.lastPage);
    }
  }, [editStudy]);

  useEffect(() => {
    if (isEdit) {
      setIsLoadingStudyForEdit(true);
      getStudyForEdit(link)
        .then((response) => {
          setInitialLastPage(response.lastPage);
          setIsLoadingStudyForEdit(false);
        })
        .catch((error) => {
          error;
        });

      sessionStorage.removeItem('formTest');
    }
  }, []);

  useEffect(() => {
    if (!localStorageForm?.testUuid && !isEdit) {
      sessionStorage.removeItem('formTest');
      getNewStudyUuid()
        .then((response) => {
          sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, testUuid: response }));
          setTestUuid(response);
        })
        .catch((error) => {
          error;
        });
    } else {
      !isEdit && getStudyFiles(localStorageForm?.testUuid);
    }
  }, []);

  useEffect(() => {
    if (!isEdit) {
      sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, step: step }));
    }
  }, [step]);

  const previewStep = async (values) => {
    if (isEdit) {
      let validationSchema;
      if (step === 1) {
        validationSchema = validationSchemaStep1;
      } else if (step === 2) {
        validationSchema = validationSchemaStep2;
      } else if (step === 3) {
        validationSchema = validationSchemaStep3;
      } else if (step === 4) {
        validationSchema = validationSchemaStep4;
      } else {
        // validationSchema = ...;
      }

      if (validationSchema) {
        try {
          await validationSchema.validate(values, { abortEarly: false });
          if (
            editStudy?.name != values.name ||
            editStudy?.status != values.status ||
            JSON.stringify(editStudy?.firstPage) !== JSON.stringify(welcomeScreen) ||
            JSON.stringify(editStudy?.parameters) !== JSON.stringify(values.parameters) ||
            initialLastPage !== lastPageScreen
          ) {
            setIsEditPreviousStepError(true);
          } else {
            setIsEditPreviousStepError(false);
            setIsEditNextStepError(false);
            setStep(step - 1);
          }
        } catch (errors) {
          setIsValid(false);
        }
      }
    } else {
      setStep(step - 1);
    }
  };

  // SECOND STEP
  const addContentToWelcomeScreen = (screenNumber, type) => {
    const updatedWelcomeScreen = [...welcomeScreen];
    const screenIndex = updatedWelcomeScreen.findIndex((item) => item.index === screenNumber);
    if (screenIndex !== -1) {
      const newContent = {
        type: type,
      };
      updatedWelcomeScreen[screenIndex].content.push(newContent);
      setWelcomeScreen(updatedWelcomeScreen);
      if (!isEdit) {
        sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, firstPage: updatedWelcomeScreen }));
      }
    }
  };

  const addWelcomeScreen = (index) => {
    const newScreen = { index: index, content: [] };
    const updatedWelcomeScreen = welcomeScreen.map((item) => {
      if (item.index >= index) {
        return { ...item, index: item.index + 1 };
      }
      return item;
    });
    updatedWelcomeScreen.splice(index - 1, 0, newScreen);
    setWelcomeScreen(updatedWelcomeScreen);
    !isEdit && sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, firstPage: updatedWelcomeScreen }));
  };

  const handleWriteTextEditor = (e, screenNumber, itemIndex) => {
    const updatedWelcomeScreen = welcomeScreen.map((item, idx) => {
      if (idx === screenNumber) {
        return {
          ...item,
          content: item.content.map((el, index) => {
            if (itemIndex === index) {
              return { ...el, text: e };
            } else {
              return el;
            }
          }),
        };
      } else {
        return item;
      }
    });
    setWelcomeScreen(updatedWelcomeScreen);
    !isEdit && sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, firstPage: updatedWelcomeScreen }));
  };

  const handleRemoveElement = async (screenNumber, itemIndex) => {
    const updatedWelcomeScreen = welcomeScreen.map((item) => {
      if (item.index === screenNumber + 1) {
        const element = item.content.find((el, index) => index === itemIndex);
        if (element.type === 'image' && element.uuid) {
          deleteStudyFile(element.uuid);
        }
        return {
          ...item,
          content: item.content.filter((el, index) => index !== itemIndex),
        };
      } else {
        return item;
      }
    });
    setWelcomeScreen(updatedWelcomeScreen);
    !isEdit && sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, firstPage: updatedWelcomeScreen }));
  };

  const handleRemoveScreen = (numb) => {
    const screen = welcomeScreen.find((item) => item.index === numb);
    const updatedWelcomeScreen = welcomeScreen.filter((item) => item.index !== numb);
    screen.content.map((item) => {
      if (item.type === 'image' && item.uuid) {
        deleteStudyFile(item.uuid);
      }
    });
    for (let i = 0; i < updatedWelcomeScreen.length; i++) {
      updatedWelcomeScreen[i].index = i + 1;
    }
    setWelcomeScreen(updatedWelcomeScreen);
    !isEdit && sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, firstPage: updatedWelcomeScreen }));
  };

  const handleImageUpload = async (file, indexImage, screenNumber) => {
    const response = await addStudyFiles(file, { index: indexImage, testUuid: testUuid || localStorageForm.testUuid });
    const firstValue = parseInt(indexImage.toString().charAt(0), 10);
    const updatedWelcomeScreen = welcomeScreen.map((element) => {
      if (element.index == screenNumber) {
        const updatedContent = element.content.map((item, index) => {
          if (index + 1 === firstValue) {
            return { ...item, ...response.file };
          }
          return item;
        });
        return { ...element, content: updatedContent };
      }
      return element;
    });

    setWelcomeScreen(updatedWelcomeScreen);
    !isEdit &&
      sessionStorage.setItem(
        'formTest',
        JSON.stringify({ ...localStorageForm, firstPage: updatedWelcomeScreen, testUuid: localStorageForm.testUuid }),
      );
  };

  // THIRD STEP

  useEffect(() => {
    if (step === 3 && isEdit && editStudy) {
      editStudy.parameters.map((item) => {
        graphicState.push(item.graphicChange);
      });
      setSelectGraphicState(graphicState);
    } else if (step === 3) {
      const parametersFromLocalStorage = localStorageForm?.parameters || [];
      const newSelectGraphicState = parametersFromLocalStorage.map((parameter) => parameter.graphicChange);
      setSelectGraphicState(newSelectGraphicState);
    }
  }, [step]);

  const addParameterScreen = (index, values, setFieldValue) => {
    const uniqueId = Math.random().toString(36).substring(7);
    const newScreen = { index: index, text: '', graphicChange: '', title: '', scaleLevel: '5', titles: ['', '', '', '', ''], uniqueId };
    const updatedParameters = [...values.parameters];
    updatedParameters.splice(index - 1, 0, newScreen);
    for (let i = index; i < updatedParameters.length; i++) {
      updatedParameters[i].index = i + 1;
    }
    setSelectGraphicState([...selectGraphicState, '']);
    setFieldValue('parameters', updatedParameters);
    !isEdit && sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, parameters: updatedParameters }));
  };

  const handleRemoveParameter = (numb, setFieldValue, values) => {
    const updatedParameterScreen = values.parameters.filter((item) => item.index !== numb);
    for (let i = 0; i < updatedParameterScreen.length; i++) {
      updatedParameterScreen[i].index = i + 1;
    }

    const updatedSelectGraphicState = [...selectGraphicState];
    updatedSelectGraphicState.splice(numb - 1, 1);
    setSelectGraphicState(updatedSelectGraphicState);
    setFieldValue('parameters', updatedParameterScreen);
    !isEdit && sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, parameters: updatedParameterScreen }));
  };

  const handleAddParameter = (e, name, setFieldTouched, setFieldValue, values, idx) => {
    const propertyName = name.split('_')[0];
    if (propertyName === 'graphicChange') {
      const updatedSelectGraphicState = [...selectGraphicState];
      updatedSelectGraphicState[idx] = e;
      setSelectGraphicState(updatedSelectGraphicState);
    }
    const updatedParameterScreen = [...values.parameters];
    updatedParameterScreen[idx] = {
      ...updatedParameterScreen[idx],
      [propertyName]: e,
    };
    setFieldTouched(name, true);
    setFieldValue('parameters', updatedParameterScreen);
    !isEdit && sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, step: step, parameters: updatedParameterScreen }));
  };

  //  FOURTH STEP

  const handleAddLastPageContent = (type, idx, setFieldValue) => {
    const updatedLastPageScreen = { ...lastPageScreen };
    const uniqueId = Math.random().toString(36).substring(7);
    const newContent =
      type === screenType.text
        ? {
            type: type,
            text: '',
            uniqueId,
          }
        : { uniqueId, type: type, titles: [], scaleLevel: 5, text: '', questionType: 'open' };

    updatedLastPageScreen.content.splice(idx + 1, 0, newContent);
    updatedLastPageScreen.content.forEach((item, index) => {
      item.index = index;
    });

    setFieldValue('lastPage', updatedLastPageScreen);
    setFieldValue(updatedLastPageScreen);
    setLastPageScreen(updatedLastPageScreen);
    !isEdit && sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, lastPage: { ...updatedLastPageScreen } }));
  };

  const handleWriteTextEditorLastPage = (e, name, setFieldValue, idx) => {
    const propertyName = name.split('_')[0];
    setLastPageScreen((prevLastPageScreen) => {
      const updatedLastPageContent = [...prevLastPageScreen.content];
      updatedLastPageContent[idx] = {
        ...updatedLastPageContent[idx],
        [propertyName]: e,
      };
      setFieldValue('lastPage', { ...prevLastPageScreen, content: updatedLastPageContent });
      return { ...prevLastPageScreen, content: updatedLastPageContent };
    });
  };

  const handleRemoveElementLastPage = (index, setFieldValue) => {
    setLastPageScreen((lastPageScreen) => {
      const updatedContent = lastPageScreen.content.filter((item, idx) => idx !== index);
      updatedContent.forEach((item, idx) => {
        item.index = idx;
      });
      const updatedLastPageContent = { ...lastPageScreen, content: updatedContent };
      !isEdit && sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, step: step, lastPage: updatedLastPageContent }));
      setFieldValue('lastPage', updatedLastPageContent);
      return updatedLastPageContent;
    });
  };

  const handleWriteFourthStep = (e, idx, name) => {
    const updatedLastPageScreen = { ...lastPageScreen };
    const contentItem = updatedLastPageScreen.content[idx];
    if (contentItem) {
      contentItem[name] = e;
      setLastPageScreen(updatedLastPageScreen);
    }
    !isEdit && sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, lastPage: updatedLastPageScreen }));
  };

  const linkValidation = (link) => {
    const lastDotIndex = link.lastIndexOf('.');
    return !link.includes(' ') && lastDotIndex > -1;
  };

  const validationSchemaStep1 = Yup.object().shape({
    name: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)

      .min(3, VALIDATION_MESSAGES.MIN_LENGTH_3)
      .max(200, VALIDATION_MESSAGES.MAX_LENGTH_200),
    status: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE),
    language: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
    finishLink: Yup.string()
      .nullable(true)
      .notRequired()
      .test('is-link', VALIDATION_MESSAGES.INVALID_LINK, (value) => (value ? linkValidation(value) : true)),
  });
  const validationSchemaStep2 = Yup.object().shape({
    firstPage: Yup.array().test('has-items', 'Minimum 1 element jest wymagany', (value) => {
      return value && value[0].content.length > 0;
    }),
  });
  const validationSchemaStep3 = Yup.object().shape({
    parameters: Yup.array().of(
      Yup.object().shape({
        title: Yup.string()
          .required(VALIDATION_MESSAGES.REQUIRED)
          .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE)
          .min(3, VALIDATION_MESSAGES.MIN_LENGTH_3)
          .max(200, VALIDATION_MESSAGES.MAX_LENGTH_200),
        text: Yup.string()
          .required(VALIDATION_MESSAGES.REQUIRED)
          .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE)
          .min(3, VALIDATION_MESSAGES.MIN_LENGTH_3)
          .max(200, VALIDATION_MESSAGES.MAX_LENGTH_200),
        graphicChange: Yup.string()
          .required(VALIDATION_MESSAGES.REQUIRED)
          .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE)
          .min(3, VALIDATION_MESSAGES.MIN_LENGTH_3)
          .max(200, VALIDATION_MESSAGES.MAX_LENGTH_200),
      }),
    ),
  });
  const validationSchemaStep4 = Yup.object().shape({
    lastPage: Yup.object().shape({
      content: Yup.array()
        .of(
          Yup.object().shape({
            text: Yup.string().required(VALIDATION_MESSAGES.REQUIRED),
          }),
        )
        .min(1, 'Minimum 1 element jest wymagany w tablicy'),
    }),
  });

  const updateStudy = async (values, testUuid, setFieldValue) => {
    let validationSchema;
    if (step === 1) {
      validationSchema = validationSchemaStep1;
    } else if (step === 2) {
      validationSchema = validationSchemaStep2;
    } else if (step === 3) {
      validationSchema = validationSchemaStep3;
    } else if (step === 4) {
      validationSchema = validationSchemaStep4;
    } else {
      // validationSchema = ...;
    }
    if (validationSchema) {
      try {
        await validationSchema.validate(values, { abortEarly: false, reset: true });
        const response = await editStudyForm({ ...values, testUuid });
        if (response) {
          await getStudyForEdit(link);
          setFieldValue('lastPage', values.lastPage);
          setLastPageScreen(values.lastPage);
          setInitialLastPage(values.lastPage);
          setIsEditNextStepError(false);
          setIsEditPreviousStepError(false);
        }
      } catch (errors) {
        const btn = document.getElementsByClassName('btn-next-click');
        if (btn && btn[0]) {
          (btn[0] as HTMLElement).click();
        }
        setIsValid(false);
      }
    }
  };

  return (
    <div className="dashboard-content">
      <div className="dashboard-header">
        <div className="dashboard-heading">
          {isEdit && (
            <p
              className="dashboard-heading-icon"
              onClick={() => navigate({ pathname: generatePath(ROUTES.STUDIES, { page: localStorageStudiesPage || 1 }) })}>
              <SVG type={SVG_TYPE.ARROW_BACK} />
            </p>
          )}
          {isEdit ? 'Edycja ' : 'Dodawanie '}
          badania
        </div>
      </div>
      <div className={`add-test__wrapper ${isFirstStep ? 'add-test__wrapper--first-step' : ''}`}>
        <div className="add-test__header">
          <div className={`add-test__header-item ${isFirstStep ? 'add-test__header-item--active' : ''}`}>Podstawowe informacje</div>
          <div className={`add-test__header-item ${isSecondStep ? 'add-test__header-item--active' : ''}`}>Ekran powitalny</div>
          <div className={`add-test__header-item ${isThirdStep ? 'add-test__header-item--active' : ''}`}>Parametry celów</div>
          <div className={`add-test__header-item ${isFourthStep ? 'add-test__header-item--active' : ''}`}>Ekran końcowy</div>
        </div>
        {isMobile && (
          <>
            <div className="add-test__header-progress">
              <div className={`add-test__header-progress__item ${step >= 1 ? 'add-test__header-progress__item--active' : ''}`} />
              <div className={`add-test__header-progress__item ${step >= 2 ? 'add-test__header-progress__item--active' : ''}`} />
              <div className={`add-test__header-progress__item ${step >= 3 ? 'add-test__header-progress__item--active' : ''}`} />
              <div className={`add-test__header-progress__item ${step >= 4 ? 'add-test__header-progress__item--active' : ''}`} />
            </div>
          </>
        )}
        <div className={`add-test__content ${isSecondStep ? 'add-test__content--second' : ''}`}>
          {isLoadingStudyForEdit ? (
            <div className="add-test__loader">
              <Loader />
            </div>
          ) : (
            <>
              <Formik
                initialValues={{
                  name: isEdit ? editStudy?.name : localStorageForm ? localStorageForm.name : '',
                  status: isEdit ? editStudy?.status : localStorageForm?.status ? localStorageForm.status : 'new',
                  language: isEdit ? editStudy?.language : localStorageForm?.language ? localStorageForm.language : 'PL',
                  finishLink: isEdit
                    ? editStudy?.finishLink != null
                      ? editStudy.finishLink
                      : null
                    : localStorageForm?.finishLink
                    ? localStorageForm.finishLink
                    : '',
                  parameters: parameterScreen,
                  firstPage: welcomeScreen,
                  lastPage: isEdit ? editStudy?.lastPage : lastPageScreen,
                }}
                validateOnMount={false}
                enableReinitialize
                validationSchema={
                  step === 1 ? validationSchemaStep1 : step === 2 ? validationSchemaStep2 : step === 3 ? validationSchemaStep3 : validationSchemaStep4
                }
                onSubmit={async (values, { setFieldValue }) => {
                  let validationSchema;
                  let isEditNextStepErrorSchema = false;
                  if (step === 1) {
                    validationSchema = validationSchemaStep1;
                  } else if (step === 2) {
                    validationSchema = validationSchemaStep2;
                  } else if (step === 3) {
                    validationSchema = validationSchemaStep3;
                  } else if (step === 4) {
                    validationSchema = validationSchemaStep4;
                  } else {
                    // validationSchema = ...;
                  }

                  if (validationSchema) {
                    try {
                      await validationSchema.validate(values, { abortEarly: false });
                      if (isEdit) {
                        if (
                          editStudy?.name != values.name ||
                          editStudy?.language != values.language ||
                          editStudy?.finishLink != values.finishLink ||
                          editStudy?.status != values.status ||
                          JSON.stringify(editStudy?.firstPage) !== JSON.stringify(welcomeScreen) ||
                          JSON.stringify(editStudy?.parameters) !== JSON.stringify(values.parameters) ||
                          initialLastPage !== lastPageScreen
                        ) {
                          setIsEditNextStepError(true);
                          isEditNextStepErrorSchema = true;
                        } else {
                          isEditNextStepErrorSchema = false;
                          setIsEditNextStepError(false);
                          setIsEditPreviousStepError(false);
                        }
                      } else {
                        isEditNextStepErrorSchema = false;
                        setIsEditNextStepError(false);
                      }

                      if (!isEditNextStepErrorSchema) {
                        if (step === 1 || step === 2) {
                          !isEdit && sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, ...values, step: step + 1 }));
                          setTimeout(() => {
                            window.scrollTo({
                              top: 0,
                              behavior: 'smooth',
                            });
                          }, 100);
                        }
                        if (step === 3) {
                          setFieldValue('parameters', values.parameters);
                          setParameterScreen(values.parameters);
                          setTimeout(() => {
                            window.scrollTo({
                              top: 0,
                              behavior: 'smooth',
                            });
                          }, 100);
                        }
                        setIsValid(true);
                        if (step === 4) {
                          let response;
                          if (isEdit) {
                            response = await editStudyForm({ ...values, testUuid });
                          } else {
                            response = await addStudy({ ...values, testUuid });
                          }
                          if (response) {
                            sessionStorage.removeItem('formTest');
                            navigate({ pathname: generatePath(ROUTES.STUDIES, { page: isEdit ? localStorageStudiesPage : '1' }) });
                          }
                        } else {
                          setStep(step + 1);
                        }
                      }
                    } catch (errors) {
                      setIsValid(false);
                    }
                  } else {
                    // setIsValid(true)
                  }
                }}>
                {({ handleSubmit, errors, values, setFieldTouched, setFieldValue, setTouched, setErrors }) => {
                  const isFourthStepError = errors.lastPage;
                  useEffect(() => {
                    if (step === 3) {
                      setTouched({});
                    }
                  }, [step]);

                  const areLinksEqual = (editStudyLink, currentLink) => {
                    const normalizedEditStudyLink = editStudyLink === null ? '' : editStudyLink;
                    const normalizedCurrentLink = currentLink === null ? '' : currentLink;
                    return normalizedEditStudyLink === normalizedCurrentLink;
                  };

                  return (
                    <form className="add-test-form" onSubmit={handleSubmit}>
                      {isFirstStep && (
                        <>
                          <Field label="Nazwa badania" name="name" placeholder="" required component={Input} errors={errors} maxLength={200} />
                          <div className="add-test-form__half add-test__status-button">
                            <Field
                              label="Status"
                              name="status"
                              placeholder=""
                              required
                              options={[
                                { value: 'new', label: 'Nowe' },
                                { value: 'active', label: 'Aktywne' },
                                { value: 'close', label: 'Zamknięte' },
                              ]}
                              component={Select}
                              errors={errors}
                            />
                          </div>
                          <div className="add-test-form__half add-test__language-button">
                            <Field
                              label="Język"
                              name="language"
                              placeholder=""
                              required
                              options={[
                                { value: 'PL', label: 'Polski' },
                                { value: 'EN', label: 'Angielski' },
                              ]}
                              component={Select}
                              errors={errors}
                            />
                          </div>
                          <div className="add-test-form__half">
                            <Field label="Link końcowy" name="finishLink" placeholder="" required component={Input} errors={errors} maxLength={200} />
                          </div>
                          {isEdit &&
                            showButton &&
                            (editStudy?.name != values.name ||
                              editStudy?.status != values.status ||
                              editStudy?.language != values.language ||
                              !areLinksEqual(editStudy?.finishLink, values.finishLink)) && (
                              <div className="add-test--save">
                                <Button
                                  onClick={() => updateStudy(values, testUuid, setFieldValue)}
                                  className="btn--frame-dartk"
                                  label="Zapisz zmiany"
                                  disabled={isValid}
                                />
                              </div>
                            )}
                        </>
                      )}
                      {isSecondStep && (
                        <>
                          <div className="add-test-form-second">
                            {welcomeScreen &&
                              welcomeScreen.map((element, idx) => {
                                const isSecondStepError = errors.firstPage;
                                const screenNumber = idx + 1;
                                return (
                                  <>
                                    <div
                                      key={idx + screenNumber}
                                      className={`add-test-form__frame ${welcomeScreen.length > 1 ? 'add-test-form__frame--border' : ''}`}>
                                      {welcomeScreen.length > 1 && (
                                        <div className="add-test-form__frame--remove" onClick={() => handleRemoveScreen(screenNumber)}>
                                          <SVG type={SVG_TYPE.BIN} />
                                        </div>
                                      )}
                                      <div className="add-test-form__heading">Ekran {screenNumber}</div>
                                      <div className="add-test-form__content">
                                        {element?.content &&
                                          element.content.map((item, itemIndex) => {
                                            let indexImage = null;
                                            indexImage = parseInt(
                                              element.content.slice(0, itemIndex + 1).length.toString() + screenNumber.toString(),
                                              10,
                                            );
                                            // }

                                            const { type } = item;
                                            return (
                                              <div key={itemIndex + 3}>
                                                {type === screenType.text && (
                                                  <div className="add-test-form__item">
                                                    <Field
                                                      value={item.text}
                                                      label="Tekst"
                                                      name="text"
                                                      placeholder=""
                                                      required
                                                      onBlur={(e) => handleWriteTextEditor(e, idx, itemIndex)}
                                                      component={TextEditor}
                                                      errors={errors}
                                                    />
                                                    <div className="add-test-form__item--remove" onClick={() => handleRemoveElement(idx, itemIndex)}>
                                                      <SVG type={SVG_TYPE.BIN} />
                                                    </div>
                                                  </div>
                                                )}
                                                {type === screenType.file && (
                                                  <div className="add-test-form__item">
                                                    <Field
                                                      value={item}
                                                      className={''}
                                                      label="Grafika"
                                                      name={`${`file_${indexImage}`}`}
                                                      placeholder="Przeciągnij i upuść lub"
                                                      required
                                                      component={DropFile}
                                                      errors={errors}
                                                      onFileDrop={(file) => handleImageUpload(file, indexImage, screenNumber)}
                                                      removeAvatar={() => handleRemoveElement(idx, itemIndex)}
                                                      accept="image/*"
                                                    />
                                                    <div className="add-test-form__item--remove" onClick={() => handleRemoveElement(idx, itemIndex)}>
                                                      <SVG type={SVG_TYPE.BIN} />
                                                    </div>
                                                  </div>
                                                )}
                                                {type === screenType.agreement && (
                                                  <div className="add-test-form__item">
                                                    <Field
                                                      value={item.text}
                                                      label="Zgoda"
                                                      name="text"
                                                      placeholder=""
                                                      required
                                                      onBlur={(e) => handleWriteTextEditor(e, idx, itemIndex)}
                                                      component={TextEditor}
                                                      errors={errors}
                                                    />
                                                    <div className="add-test-form__item--remove" onClick={() => handleRemoveElement(idx, itemIndex)}>
                                                      <SVG type={SVG_TYPE.BIN} />
                                                    </div>
                                                  </div>
                                                )}
                                              </div>
                                            );
                                          })}
                                      </div>
                                      <div className="add-test-form-second__buttons">
                                        <div className="add-test-form-second__buttons-content">
                                          <Button
                                            label="Dodaj tekst"
                                            iconClass="icon-right"
                                            svg_type={SVG_TYPE.TEXT}
                                            black
                                            onClick={() => addContentToWelcomeScreen(screenNumber, screenType.text)}
                                          />
                                          <Button
                                            label="Dodaj grafikę"
                                            iconClass="icon-right"
                                            svg_type={SVG_TYPE.IMAGE}
                                            black
                                            onClick={() => addContentToWelcomeScreen(screenNumber, screenType.file)}
                                          />
                                          <Button
                                            label="Dodaj zgodę"
                                            iconClass="icon-right"
                                            svg_type={SVG_TYPE.AGREEMENT}
                                            black
                                            onClick={() => addContentToWelcomeScreen(screenNumber, screenType.agreement)}
                                          />
                                        </div>
                                        <div className="add-test-form-second__buttons-error">
                                          {isSecondStepError && 'Uzupełnienie tego kroku jest obowiązkowe. Dodaj elementy, aby przejść dalej.'}
                                        </div>
                                      </div>
                                    </div>
                                    {((idx === 0 && element.content.length > 0) || idx != 0) && (
                                      <Button fullBlack label="Dodaj kolejny ekran +" onClick={() => addWelcomeScreen(idx + 2)} />
                                    )}
                                  </>
                                );
                              })}
                          </div>
                          {isEdit && JSON.stringify(editStudy?.firstPage) !== JSON.stringify(welcomeScreen) && (
                            <div className="add-test--save add-test--save-second-step">
                              <Button
                                onClick={() => updateStudy(values, testUuid, setFieldValue)}
                                className="btn--frame-dartk"
                                label="Zapisz zmiany"
                                disabled={isValid}
                              />
                            </div>
                          )}
                        </>
                      )}
                      {isThirdStep && (
                        <div className="add-test-form-third">
                          {values.parameters &&
                            values.parameters.map((item, idx) => {
                              const uuidOrUniqueId = item.uuid || item.uniqueId;
                              return (
                                <>
                                  <div
                                    key={uuidOrUniqueId}
                                    className={`add-test-form__frame add-test-form__frame--third ${
                                      values.parameters.length > 1 ? 'add-test-form__frame--border' : ''
                                    }`}>
                                    {values.parameters.length > 1 && (
                                      <div
                                        className="add-test-form__frame--remove"
                                        onClick={() => handleRemoveParameter(idx + 1, setFieldValue, values)}>
                                        <SVG type={SVG_TYPE.BIN} />
                                      </div>
                                    )}
                                    <div className="add-test-form__heading">Parametr {idx + 1} </div>
                                    <div className="add-test-form__half">
                                      <Field
                                        fieldValue={item.title}
                                        maxLength={120}
                                        label="Tytuł"
                                        arrayName="parameters"
                                        index={idx}
                                        name={`title_${uuidOrUniqueId}`}
                                        placeholder=""
                                        required
                                        component={Input}
                                        errors={errors}
                                        onChange={(e) =>
                                          handleAddParameter(e.target.value, `title_${uuidOrUniqueId}`, setFieldTouched, setFieldValue, values, idx)
                                        }
                                      />
                                      <Field
                                        maxLength={30}
                                        fieldValue={item.graphicChange}
                                        label="Zmiana graficzna"
                                        name={`graphicChange_${uuidOrUniqueId}`}
                                        index={idx}
                                        placeholder=""
                                        required
                                        arrayName="parameters"
                                        options={selectCircleOptions.filter((option) => {
                                          return (
                                            option.value === 'noGraphics' ||
                                            !selectGraphicState.includes(option.value) ||
                                            item.graphicChange === option.value
                                          );
                                        })}
                                        component={Select}
                                        errors={errors}
                                        onChange={(e) => {
                                          handleAddParameter(e.value, `graphicChange_${uuidOrUniqueId}`, setFieldTouched, setFieldValue, values, idx);
                                        }}
                                      />
                                    </div>
                                    <Field
                                      fieldValue={item.text}
                                      maxLength={200}
                                      className="additional-description"
                                      label="Dodatkowy opis"
                                      name={`text_${uuidOrUniqueId}`}
                                      arrayName="parameters"
                                      capsDisabled
                                      index={idx}
                                      placeholder=""
                                      required
                                      component={Input}
                                      errors={errors}
                                      onChange={(e) =>
                                        handleAddParameter(e.target.value, `text_${uuidOrUniqueId}`, setFieldTouched, setFieldValue, values, idx)
                                      }
                                    />
                                    <div className="add-test-form__half">
                                      <Field
                                        fieldValue={item.scaleLevel || 5}
                                        maxLength={30}
                                        type="number"
                                        label="Ilość stopni w skali"
                                        name={`scaleLevel_${uuidOrUniqueId}`}
                                        placeholder=""
                                        arrayName="parameters"
                                        index={idx}
                                        required
                                        component={Input}
                                        errors={errors}
                                        onBlur={(e) =>
                                          handleAddParameter(e, `scaleLevel_${uuidOrUniqueId}`, setFieldTouched, setFieldValue, values, idx)
                                        }
                                        className="pointerEventsNone"
                                      />
                                    </div>
                                    <CustomParameterSlider
                                      itemScale={item.scaleLevel}
                                      values={values}
                                      index={idx}
                                      setFieldValue={setFieldValue}
                                      localStorageForm={localStorageForm || editStudy || {}}
                                    />
                                  </div>
                                  <Button
                                    fullBlack
                                    label="Dodaj kolejny parametr +"
                                    onClick={() => addParameterScreen(idx + 2, values, setFieldValue)}
                                  />
                                </>
                              );
                            })}

                          {isEdit && JSON.stringify(editStudy?.parameters) !== JSON.stringify(values.parameters) && (
                            <div className="add-test--save add-test--save-third-step">
                              <Button
                                onClick={() => updateStudy(values, testUuid, setFieldValue)}
                                className="btn--frame-dartk"
                                label="Zapisz zmiany"
                                disabled={isValid}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {isFourthStep && (
                        <div className="add-test-form-fourth">
                          {lastPageScreen &&
                            lastPageScreen?.content?.length > 0 &&
                            lastPageScreen?.content.map((item, idx) => {
                              const questionTypeFieldName = `questionType_${item.uniqueId ? item.uniqueId : item.id}`;
                              const scaleLevelFieldName = `scaleLevel_${item.uniqueId}`;
                              const isQuestion = item.type === screenType.question;
                              let questionNumber = null;
                              if (isQuestion) {
                                questionNumber = lastPageScreen.content.slice(0, idx + 1).filter((item) => item.type === screenType.question).length;
                              }
                              const uuidOrUniqueId = item.uuid || item.uniqueId;
                              return (
                                <>
                                  {item.type === screenType.text && (
                                    <div className="add-test-form__item" key={`item-${uuidOrUniqueId}`}>
                                      <Field
                                        value={item.text}
                                        label="Tekst"
                                        name={`text_${uuidOrUniqueId}`}
                                        placeholder=""
                                        arrayName="lastPage"
                                        index={idx}
                                        required
                                        component={TextEditor}
                                        errors={errors}
                                        onBlur={(e) => handleWriteTextEditorLastPage(e, `text_${uuidOrUniqueId}`, setFieldValue, idx)}
                                      />
                                      <div
                                        className="add-test-form__item--remove"
                                        onClick={() => {
                                          handleRemoveElementLastPage(idx, setFieldValue);
                                        }}>
                                        <SVG type={SVG_TYPE.BIN} />
                                      </div>
                                    </div>
                                  )}
                                  {item.type === screenType.question && (
                                    <div
                                      key={`question-${uuidOrUniqueId}`}
                                      className={`add-test-form__frame ${lastPageScreen.content.length > 1 ? 'add-test-form__frame--border' : ''}`}>
                                      {lastPageScreen.content.length > 1 && (
                                        <div className="add-test-form__frame--remove" onClick={() => handleRemoveElementLastPage(idx, setFieldValue)}>
                                          <SVG type={SVG_TYPE.BIN} />
                                        </div>
                                      )}
                                      <div className="add-test-form__heading">{`Pytanie ${questionNumber}`} </div>
                                      <Field
                                        fieldValue={item.text}
                                        label="Treść pytania"
                                        arrayName="lastPage"
                                        index={idx}
                                        name={`text_${uuidOrUniqueId}`}
                                        placeholder=""
                                        required
                                        component={Input}
                                        errors={errors}
                                        onChange={(e) => handleWriteFourthStep(e.target.value, idx, 'text')}
                                      />
                                      <div className="add-test-form__item">
                                        <div className="add-test-form__half">
                                          <Field
                                            fieldValue={item.questionType}
                                            label="Typ pytania"
                                            name={questionTypeFieldName}
                                            placeholder=""
                                            required
                                            options={[
                                              { value: 'open', label: 'Pytanie otwarte' },
                                              { value: 'scale', label: 'Wybór skali' },
                                            ]}
                                            component={Select}
                                            errors={errors}
                                            onChange={(e) => handleWriteFourthStep(e.value, idx, 'questionType')}
                                          />
                                          {item.questionType === 'scale' && (
                                            <Field
                                              fieldValue={item.scaleLevel || 5}
                                              maxLength={30}
                                              type="number"
                                              label="Ilość stopni w skali"
                                              name={scaleLevelFieldName}
                                              placeholder=""
                                              required
                                              className="pointerEventsNone"
                                              component={Input}
                                              errors={errors}
                                              onBlur={(e) => handleWriteFourthStep(e, idx, `scaleLevel`)}
                                            />
                                          )}
                                        </div>
                                        {item.questionType === 'scale' && (
                                          <CustomLastPageSlider
                                            index={idx}
                                            lastPage={lastPageScreen}
                                            setFieldValue={setFieldValue}
                                            lastPageScreen={lastPageScreen.content}
                                            setLastPageScreen={setLastPageScreen}
                                            localStorageForm={editStudy || localStorageForm || {}}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  )}
                                  <div className="add-test-form-fourth__buttons">
                                    <Button
                                      label="Dodaj tekst"
                                      iconClass="icon-right"
                                      svg_type={SVG_TYPE.TEXT}
                                      black
                                      onClick={() => handleAddLastPageContent('text', idx, setFieldValue)}
                                    />
                                    <Button
                                      label="Dodaj pytanie"
                                      iconClass="icon-right"
                                      svg_type={SVG_TYPE.QUESTION}
                                      black
                                      onClick={() => handleAddLastPageContent('question', idx, setFieldValue)}
                                    />
                                  </div>
                                </>
                              );
                            })}
                          {lastPageScreen && lastPageScreen?.content?.length === 0 && (
                            <>
                              <div className="add-test-form-fourth__buttons">
                                <Button
                                  label="Dodaj tekst"
                                  iconClass="icon-right"
                                  svg_type={SVG_TYPE.TEXT}
                                  black
                                  onClick={() => handleAddLastPageContent('text', 0, setFieldValue)}
                                />
                                <Button
                                  label="Dodaj pytanie"
                                  iconClass="icon-right"
                                  svg_type={SVG_TYPE.QUESTION}
                                  black
                                  onClick={() => handleAddLastPageContent('question', 0, setFieldValue)}
                                />
                              </div>
                              <div className="add-test-form-second__buttons-error">
                                {isFourthStepError && 'Uzupełnienie tego kroku jest obowiązkowe. Dodaj elementy, aby przejść dalej.'}
                              </div>
                            </>
                          )}
                          {isEdit && initialLastPage !== lastPageScreen && (
                            <div className="add-test--save add-test--save-third-step">
                              <Button
                                onClick={() => {
                                  setErrors({});
                                  updateStudy({ ...values, lastPage: lastPageScreen }, testUuid, setFieldValue);
                                }}
                                className="btn--frame-dartk"
                                label="Zapisz zmiany"
                                disabled={isValid}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      <div className={`add-test__buttons ${isFirstStep ? 'add-test__buttons-first-step' : ''}`}>
                        <div className="add-test__buttons--next">
                          {!isFirstStep && <Button className="btn--frame-dartk" label="Wróć" stroke onClick={() => previewStep(values)} />}
                          {isEditPreviousStepError && (
                            <div className="add-test__buttons--error">Zapisz zmiany, aby przejść do poprzedniego kroku</div>
                          )}
                        </div>
                        <div className="add-test__buttons--next">
                          <Button
                            className="btn--frame-dark btn-next-click"
                            label={`${isFourthStep ? 'Zakończ' : 'Dalej'}`}
                            disabled={isValid}
                            type="submit"
                            stroke={isEdit ? true : false}
                            onClick={() => handleSubmit}
                          />
                          {isEditNextStepError && <div className="add-test__buttons--error">Zapisz zmiany, aby przejść dalej</div>}
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddStudyPage;
