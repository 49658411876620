import { useEffect, useState } from 'react';
import { Modal, Button } from 'Shared/components';
import { getRectOfNodes, getTransformForBounds } from 'reactflow';
import { toPng } from 'html-to-image';

type EndMapModalType = {
  handleEndMap: any;
  isOpenModal: any;
  close: any;
  nodes: any;
  addFileMap: any;
  setIsLoadingButton: any;
  isLoadingButton: boolean;
};

const EndMapModal = ({ setIsLoadingButton, nodes, handleEndMap, close, isOpenModal, addFileMap, isLoadingButton }: EndMapModalType) => {
  const [mapCanvas, setMapCanvas] = useState(null);
  const language = sessionStorage.getItem('language');
  const isEnLanguage = language === 'EN';

  useEffect(() => {
    mapCanvas &&
      addFileMap(mapCanvas).then((mapUuid) => {
        handleEndMap(mapUuid);
      });
  }, [mapCanvas]);

  const makeScreenShot = async () => {
    const imageWidth = 1024;
    const imageHeight = 768;
    const viewportElement = document.querySelector('.react-flow__viewport') as HTMLElement;
    const nodesBounds = getRectOfNodes(nodes);

    const transform = getTransformForBounds(nodesBounds, imageWidth, imageHeight, 0.5, 2);
    if (viewportElement) {
      await toPng(viewportElement, {
        width: imageWidth,
        height: imageHeight,
        style: {
          width: `${imageWidth}px`,
          height: `${imageHeight}px`,
          transform: `translate(${transform[0]}px, ${transform[1]}px) scale(${transform[2]})`,
        },
      }).then((canvas) => {
        sessionStorage.setItem('imgMap', canvas);
        setMapCanvas(canvas);
      });
    }
  };

  return (
    <Modal
      className="top-bar__end-map-modal"
      isOpen={isOpenModal}
      onClose={() => {
        close();
      }}>
      <div className="modal-heading">{isEnLanguage ? 'End survey' : 'Zakończ badanie'}</div>
      <div className="modal-description">
        {isEnLanguage
          ? 'Are you sure you have added all goals and connections and want to finish creating the map?'
          : 'Czy na pewno dodałeś/aś wszystkie cele i połączenia i chcesz zakończyć tworzenie mapy?'}
      </div>
      <div className="top-bar__end-map-modal__buttons">
        <Button label={isEnLanguage ? 'Cancel' : 'Anuluj'} stroke onClick={() => close()} />
        <Button
          loader={isLoadingButton}
          label={isEnLanguage ? 'Confirm' : 'Potwierdź'}
          onClick={async () => {
            await setIsLoadingButton(true);
            makeScreenShot();
          }}
        />
        {/* <Button label="Potwierdź" onClick={() => handleEndMap()} /> */}
      </div>
    </Modal>
  );
};

export default EndMapModal;
