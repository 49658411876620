import { useState } from 'react';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

type BoardInfoType = {
  nodes: any;
  formValues: any;
  isMissingParameter: any;
  whichEdgeControlsIsOpen: any;
  isEdgeControlsOpened?: any;
  selectedNode?: any;
};

const BoardInfo = ({ nodes, formValues, isMissingParameter, isEdgeControlsOpened, selectedNode }: BoardInfoType) => {
  const [isOpen, setIsopen] = useState(true);
  const language = sessionStorage.getItem('language');
  const isEnLanguage = language === 'EN';

  function isObjectNotEmpty() {
    if (!formValues.hasOwnProperty('descript')) {
      return true;
    }
    for (const key in formValues) {
      if (key !== 'descript' && (formValues[key] === '' || formValues[key] === '0')) {
        return false;
      }
    }
    return true;
  }
  {
    // console.log(isMissingParameter, 'tu?');
  }

  return (
    <>
      <div className={`board-info__wrapper`}>
        <div className={`board-info ${!isOpen ? 'board-info--hidden' : ''}`}>
          {isOpen && (
            <>
              <div className="board-info__close-wrapper" onClick={() => setIsopen(false)}>
                {isEnLanguage ? 'Close' : 'Zamknij'}
                <SVG className="board-info__close" type={SVG_TYPE.CLOSE} />
              </div>
            </>
          )}
          <div className="board-info__icon-wrapper board-info__icon-wrapper--pointer" onClick={() => setIsopen(!isOpen)}>
            <SVG type={SVG_TYPE.INFO} />
          </div>
          {isOpen && (
            <div className="board-info__text">
              {isEdgeControlsOpened ? (
                // {whichEdgeControlsIsOpen ? (
                <>
                  <p>
                    {isEnLanguage
                      ? 'An arrow indicates how one goal influences another.'
                      : 'Strzałka oznacza, w jaki sposób jeden cel wpływa na drugi.'}
                  </p>
                  <p>
                    {isEnLanguage
                      ? 'A negative influence means that achieving one goal hinders and interferes with the achievement of another goal. In that case, the arrow is red. '
                      : 'Negatywny wpływ oznacza, że realizacja danego celu utrudnia i przeszkadza w realizacji drugiego celu. Strzałka jest wtedy czerwona.'}
                  </p>
                  <p>
                    {isEnLanguage
                      ? 'A positive influence means that achieving one goal facilitates and promotes the achievement of another goal. In that case, the arrow is green.'
                      : 'Pozytywny wpływ oznacza, że realizacja jednego celu ułatwia i sprzyja realizacji drugiego celu. Strzałka jest wtedy zielona.'}
                  </p>
                  <p>
                    {isEnLanguage
                      ? 'The thickness of the arrow indicates how strongly one goal influences another.'
                      : 'Grubość strzałki oznacza to, jak silny jest wpływ danego celu na drugi.'}
                  </p>
                  <p>
                    {isEnLanguage
                      ? ' If you want to indicate that two goals influence each other, draw two arrows - each in one direction.'
                      : 'Jeśli chcesz zaznaczyć, że dwa cele nawzajem na siebie wpływają narysuj dwie strzałki - każdą w jednym kierunku.'}
                  </p>
                  {/* {whichEdgeControlsIsOpen === 'influence' && (
                    <>
                      <p>Strzałka oznacza, w jaki sposób jeden cel wpływa na drugi.</p>
                      <p>
                        Negatywny wpływ oznacza, że realizacja danego celu utrudnia i przeszkadza w realizacji drugiego celu. Strzałka jest wtedy
                        czerwona.
                      </p>
                      <p>
                        Pozytywny wpływ oznacza, że realizacja jednego celu ułatwia i sprzyja realizacji drugiego celu. Strzałka jest wtedy zielona.
                      </p>
                      <p>Grubość strzałki oznacza to, jak silny jest wpływ danego celu na drugi.</p>
                      <p>Jeśli chcesz zaznaczyć, że dwa cele nawzajem na siebie wpływają narysuj dwie strzałki - każdą w jednym kierunku.</p>
                    </>
                  )}{' '}
                  {whichEdgeControlsIsOpen === 'powerInfluence' && (
                    <>
                      <p>Strzałka oznacza, w jaki sposób jeden cel wpływa na drugi.</p>
                      <p>
                        Negatywny wpływ oznacza, że realizacja danego celu utrudnia i przeszkadza w realizacji drugiego celu. Strzałka jest wtedy
                        czerwona.
                      </p>
                      <p>
                        Pozytywny wpływ oznacza, że realizacja jednego celu ułatwia i sprzyja realizacji drugiego celu. Strzałka jest wtedy zielona.
                      </p>
                      <p>Grubość strzałki oznacza to, jak silny jest wpływ danego celu na drugi.</p>
                      <p>Jeśli chcesz zaznaczyć, że dwa cele nawzajem na siebie wpływają narysuj dwie strzałki - każdą w jednym kierunku.</p>
                    </>
                  )} */}
                </>
              ) : (
                <>
                  {nodes?.length <= 0 && (
                    <>
                      {isEnLanguage
                        ? 'Your goal map is empty. Click "add goal" button to start.'
                        : ' Twoja mapa celów jest pusta. Kliknij przycisk “dodaj cel”, aby rozpocząć.'}
                    </>
                  )}
                  {nodes?.length >= 1 && (
                    <>
                      {isObjectNotEmpty() ? (
                        <>
                          {nodes?.length >= 2 && !selectedNode ? (
                            <>
                              <p>
                                {' '}
                                {isEnLanguage
                                  ? 'You can now add another goal or add a connection between goals.'
                                  : 'Możesz teraz dodać kolejny cel lub dodać połączenie między celami.'}
                              </p>
                              <p>
                                {isEnLanguage
                                  ? 'To add a connection, click on the outline of the goal you wish to connect. After clicking, an arrow will appear; drag this arrow to another goal. The goal from which the arrow originates influences the goal to which it points.'
                                  : 'Połączenie możesz dodać klikając na obrys celu, którego wpływ chcesz zaznaczyć. Po kliknięciu pojawi się strzałka, którą należy przeciągnąć do innego celu. Cel, z którego wychodzi strzałka wpływa na cel, który ona wskazuje.'}
                              </p>
                              <p>
                                {isEnLanguage
                                  ? 'After drawing the arrow, specify its type (negative or positive) and strength (small or large) of this influence.'
                                  : 'Po narysowaniu strzałki zaznacz rodzaj (negatywny vs. pozytywny) i siłę (małą vs. dużą) tego wpływu.'}
                              </p>
                              <p>
                                {isEnLanguage
                                  ? 'If you want to indicate mutual influence between two goals, draw two arrows in opposite directions. These influences can be different.'
                                  : 'Jeśli chcesz zaznaczyć, że dwa cele nawzajem na siebie wpływają narysuj dwie strzałki – każdą w jednym kierunku. Ten wpływ nie musi być taki sam.'}
                              </p>
                            </>
                          ) : (
                            <>
                              {nodes?.length === 1 && !selectedNode ? (
                                <p>{isEnLanguage ? 'You can now add another goal.' : 'Możesz teraz dodać kolejny cel.'}</p>
                              ) : (
                                <>
                                  <p>
                                    {isEnLanguage
                                      ? 'Name and describe your goal using the editing panel on the right side.'
                                      : 'Nazwij i scharakteryzuj swój cel korzystając z panelu edycji, który widzisz po prawej stronie.'}
                                  </p>
                                  <p>
                                    {isEnLanguage
                                      ? 'You can also edit this goal later by clicking on it.'
                                      : 'Możesz także edytować ten cel później, klikając w niego.'}
                                  </p>
                                  <p>
                                    {isEnLanguage
                                      ? `To change the goal's position, drag it anywhere on the screen.`
                                      : 'Możesz zmienić lokalizację celu przesuwając go na ekranie.'}
                                  </p>
                                </>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <p>
                            {isEnLanguage
                              ? 'Name and describe your goal using the editing panel on the right side.'
                              : 'Nazwij i scharakteryzuj swój cel korzystając z panelu edycji, który widzisz po prawej stronie.'}
                          </p>
                          <p>
                            {isEnLanguage
                              ? 'You can also edit this goal later by clicking on it.'
                              : 'Możesz także edytować ten cel później, klikając w niego.'}
                          </p>
                          <p>
                            {isEnLanguage
                              ? `To change the goal's position, drag it anywhere on the screen.`
                              : 'Możesz zmienić lokalizację celu przesuwając go na ekranie.'}
                          </p>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
        {((isMissingParameter && !isObjectNotEmpty()) || (isMissingParameter && isEdgeControlsOpened)) && (
          <>
            <div className={`board-info `}>
              <div className="board-info__icon-wrapper">
                <SVG type={SVG_TYPE.WARNING_INFO} />
              </div>
              <div className="board-info__text board-info__text--error">
                {isEnLanguage
                  ? 'To close the goal editing panel, you need to fill in all the required parameters.'
                  : 'Aby zamknąć panel edycji celu, należy uzupełnić wszystkie niezbędne parametry.'}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BoardInfo;
