import { useState, useEffect } from 'react';
import 'rc-slider/assets/index.css';
import { default as RcSlider } from 'rc-slider';
import { Button, SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

type CustomSliderType = {
  lastPageScreen: any;
  setLastPageScreen: any;
  index: number;
  localStorageForm: any;
  setFieldValue: any;
  lastPage: any;
};

const CustomSlider = ({ lastPageScreen, index, setLastPageScreen, localStorageForm, setFieldValue, lastPage }: CustomSliderType) => {
  const isMobile = window.innerWidth < 1024;
  const [scaleLevels, setScaleLevels] = useState(lastPageScreen.map((item) => item.scaleLevel || 5));
  const [activeMark, setActiveMark] = useState(null);
  const arrayOfStrings = [];
  const scaleLevelsIndex = scaleLevels[index];
  const [inputValues, setInputValues] = useState(arrayOfStrings);
  const [marksHeight, setMarksHeight] = useState(null);

  useEffect(() => {
    const newArrayOfStrings = [];
    for (let i = 0; i < scaleLevelsIndex; i++) {
      const titleFromValues = (lastPageScreen && lastPageScreen[index] && lastPageScreen[index]?.titles && lastPageScreen[index]?.titles[i]) || '';
      newArrayOfStrings.push(titleFromValues);
    }
    setInputValues(newArrayOfStrings);
  }, [lastPageScreen]);

  useEffect(() => {
    for (let i = 0; i < scaleLevelsIndex; i++) {
      const titleFromLocalStorage =
        (localStorageForm.lastPage &&
          localStorageForm.lastPage &&
          localStorageForm.lastPage.content[index] &&
          localStorageForm.lastPage.content[index]?.titles &&
          localStorageForm.lastPage.content[index]?.titles[i]) ||
        '';
      arrayOfStrings.push(titleFromLocalStorage);
    }
  }, []);

  useEffect(() => {
    const slider = document.getElementsByClassName('slider-param');
    const sliderMarks = slider && slider[0].getElementsByClassName('rc-slider-mark');
    const marks = sliderMarks && sliderMarks[0].getElementsByClassName('rc-slider-mark-text');
    let maxHeight = 0;
    for (let i = 0; i < marks.length; i++) {
      const element = marks[i];
      const elementHeight = element.clientHeight;
      if (elementHeight > maxHeight) {
        maxHeight = elementHeight;
      }
    }
    setMarksHeight(maxHeight);
  }, [activeMark]);

  useEffect(() => {
    const updatedScale = lastPageScreen.map((item) => item.scaleLevel || 5);
    setScaleLevels(updatedScale);
  }, [lastPageScreen.map((item) => item.scaleLevel)]);

  const handleMarkClick = (mark) => {
    setActiveMark(mark);
    setTimeout(() => {
      const input = document.getElementById(`input-mark-${mark}`);
      if (input) {
        input.focus();
      }
    }, 0);
  };

  const handleInputBlur = () => {
    setActiveMark(null);
    const updatedLastPageScreen = [...lastPageScreen];
    updatedLastPageScreen[index] = {
      ...updatedLastPageScreen[index],
      titles: [...inputValues],
    };
    console.log(updatedLastPageScreen, 'updatowane!');
    setFieldValue('lastPage', updatedLastPageScreen);
    setLastPageScreen({ ...lastPage, content: updatedLastPageScreen });
    sessionStorage.setItem('formTest', JSON.stringify({ ...localStorageForm, lastPage: { ...lastPage, content: updatedLastPageScreen } }));
  };

  const handleInputChange = (mark, value) => {
    const newInputValues = [...inputValues];
    newInputValues[mark - 1] = value;
    setInputValues(newInputValues);
  };

  const generateMobileForm = () => {
    const numMarks = scaleLevels[index];
    const formElements = [];
    for (let i = 1; i <= numMarks; i++) {
      formElements.push(
        <div className="test-form-slider-mark">
          <div className="test-form-slider-mark__form-input-label">{i} stopień</div>
          <div className="test-form-slider-mark__form-input">
            <input
              id={`input-mark-${i}`}
              value={inputValues[i - 1] || ''}
              onChange={(e) => handleInputChange(i, e.target.value)}
              onBlur={() => handleInputBlur()}
            />
          </div>
        </div>,
      );
    }

    return formElements;
  };

  const generateMarks = () => {
    const numMarks = scaleLevels[index];
    const marks = {};

    const handleInputKeyDown = (e) => {
      if (e.code === 'Enter') {
        e.preventDefault();
        setActiveMark(null);
      }
    };

    for (let i = 1; i <= numMarks; i++) {
      marks[i] = {
        label: (
          <div
            className={`test-form-slider-mark ${
              numMarks > 5 || (numMarks <= 5 && activeMark == 0) ? 'test-form-slider-mark--small' : 'test-form-slider-mark--big'
            } ${(numMarks > 5 && inputValues[i - 1]) || activeMark === i ? 'test-form-slider-mark--big' : ''}`}>
            <div className="test-form-slider-mark__number">{i}</div>
            {!isMobile && (
              <>
                {activeMark === i ? (
                  <div className="test-form-slider-mark__input">
                    <input
                      id={`input-mark-${i}`}
                      value={inputValues[i - 1] || ''}
                      onChange={(e) => handleInputChange(i, e.target.value)}
                      onBlur={() => handleInputBlur()}
                      onKeyDown={(e) => handleInputKeyDown(e)}
                      maxLength={60}
                    />
                  </div>
                ) : (
                  <>
                    {inputValues[i - 1] ? (
                      <div className="test-form-slider-mark__input-label" onClick={() => handleMarkClick(i)}>
                        <div className="text">{inputValues[i - 1]}</div>
                        <SVG type={SVG_TYPE.EDIT} />
                      </div>
                    ) : (
                      <Button
                        className={`${numMarks > 5 ? 'btn-small' : ''}`}
                        label={`${numMarks > 5 ? '+' : '+ Podpis'}`}
                        stroke
                        black
                        onClick={() => handleMarkClick(i)}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        ),
      };
    }
    return marks;
  };
  const marks = generateMarks();

  return (
    <div style={{ height: `${marksHeight + 15}px` }}>
      <RcSlider className="slider-param" marks={marks} dots max={scaleLevels[index]} min={1} />
      {isMobile && <div className="test-form-slider-mark__form-inputs">{generateMobileForm()}</div>}
    </div>
  );
};

export default CustomSlider;
