enum SVG_TYPE {
  BIN = 'BIN',
  INFO = 'INFO',
  CLOSE = 'CLOSE',
  LOGO = 'LOGO',
  ARROW_BACK = 'ARROW_BACK',
  CHEVRONE = 'CHEVRONE',
  EYE = 'EYE',
  EYE_OFF = 'EYE_OFF',
  CIRCLE_GREEN = 'CIRCLE_GREEN',
  CIRCLE_RED = 'CIRCLE_RED',
  WARNING = 'WARNING',
  CHECKED = 'CHECKED',
  ADMINISTRATORS = 'ADMINISTRATORS',
  TESTS = 'TESTS',
  DOTS = 'DOTS',
  PLUS = 'PLUS',
  HAMBURGER = 'HAMBURGER',
  DOWNLOAD_FILE = 'DOWNLOAD_FILE',
  BACK_CHEVRON = 'BACK_CHEVRON',
  TEXT = 'TEXT',
  IMAGE = 'IMAGE',
  AGREEMENT = 'AGREEMENT',
  EDIT = 'EDIT',
  QUESTION = 'QUESTION',
  MINUS_GRAY = 'MINUS_GRAY',
  PLUS_GRAY = 'PLUS_GRAY',
  GOALMAPP = 'GOALMAPP',
  SYGNET = 'SYGNET',
  GOALMAPP_WHITE = 'GOALMAPP_WHITE',
  GOALMAPP_WITHOUT_PADDING = 'GOALMAPP_WITHOUT_PADDING',
  COPY = 'COPY',
  GET_LOST = 'GET_LOST',
  CHECKBOX_CHECKED = 'CHECKBOX_CHECKED',
  STUDY_FINISHED = 'STUDY_FINISHED',
  WARNING_INFO = 'WARNING_INFO',
}

export default SVG_TYPE;
