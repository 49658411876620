import { Modal, Button } from 'Shared/components';

type RemoveTargetModalType = {
  handleRemoveTarget: any;
  isOpenModal: any;
  close: any;
};

const RemoveTargetModal = ({ handleRemoveTarget, close, isOpenModal }: RemoveTargetModalType) => {
  const language = sessionStorage.getItem('language');
  const isEnLanguage = language === 'EN';
  return (
    <Modal
      className="top-bar__end-map-modal"
      isOpen={isOpenModal}
      onClose={() => {
        close();
      }}>
      <div className="modal-heading">{isEnLanguage ? 'Goal deleting' : 'Usuwanie celu'}</div>
      <div className="modal-description">
        {isEnLanguage
          ? 'Are you sure you want to delete the goal and its connections?'
          : 'Czy na pewno chcesz usunąć cel i połączenia z nim związane?'}
      </div>
      <div className="top-bar__end-map-modal__buttons">
        <Button label={isEnLanguage ? 'Cancel' : 'Anuluj'} stroke onClick={() => close()} />
        <Button label={isEnLanguage ? 'Delete' : 'Usuń'} onClick={() => handleRemoveTarget()} />
      </div>
    </Modal>
  );
};

export default RemoveTargetModal;
