import { useEffect, useState } from 'react';
import { FieldProps } from 'formik';
import { SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

interface InputProps {
  label: string;
  name: string;
  type: string;

  placeholder?: string;
  className?: string;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  secondValue?: string;
  multi?: boolean;
  treshold?: number;
  removable?: boolean;
  maxLength?: number;
  id?: string;
  inputMode?: string;
  pattern?: string;
  variant?: string;
  inputOnFocus?: any;
  fieldValue?: any;
  arrayName?: string;
  index?: number;

  //formik
  field: any;
  errors: any;
  form: any;
  touched?: any;
  onChange?: (value) => void;
  handleOnChange?: any;
  isOnBlurAction: boolean;
  editUser: (data) => void;
  onFocus: any;
  onBlur?: any;
  capsDisabled?: any;
}

function Input({
  label,
  className = '',
  name,
  placeholder,
  required,
  multi,
  type,
  form,
  errors = {},
  field,
  onChange,
  treshold = 500,
  removable,
  maxLength,
  id,
  inputMode,
  pattern,
  onBlur,
  inputOnFocus,
  fieldValue,
  arrayName,
  index,
  capsDisabled,
}: InputProps & FieldProps) {
  const [inputFirstPart, setInputFirstPart] = useState<string>(field?.value?.inputFirstPart);
  const [inputSecondPart, setInputSecondPart] = useState<string>(field?.value?.inputSecondPart);
  const [value, setValue] = useState<string>(field?.value ? field.value : fieldValue || '');
  const [debouncedValues, setDebouncedValues] = useState<string>(field?.value);
  const [showPassword, setShowPassword] = useState(false);
  const isPassword = type === 'password';
  const inputErrorClass = `${form.touched[field.name] && errors[field.name] ? 'input--border-error' : ''}`;
  const numericValue = !isNaN(parseFloat(value)) ? parseFloat(value) : 0;
  const [numericStateValue, setNumericStateValue] = useState<number>(numericValue);

  useEffect(() => {
    if (type === 'number') {
      setNumericStateValue(!isNaN(parseFloat(fieldValue)) ? parseFloat(fieldValue) : 0);
    } else {
      setValue(fieldValue);
    }
  }, [fieldValue]);

  const formTouched = (name) => {
    let validate;
    if (arrayName) {
      const propertyName = name.split('_')[0];
      if (arrayName != 'lastPage') {
        form.touched &&
          form.touched[arrayName] &&
          form.touched[arrayName].map((item) => {
            validate = item[propertyName];
          });
      } else {
        form.touched &&
          form.touched[arrayName] &&
          form.touched[arrayName].content.map((item) => {
            validate = item[propertyName];
          });
      }
    } else {
      validate = form.touched[name];
    }
    return validate;
  };

  const errorField = (name) => {
    let validate;
    if (arrayName) {
      const propertyName = name.split('_')[0];
      if (arrayName != 'lastPage') {
        validate = errors[arrayName] && errors[arrayName][index] && errors[arrayName][index][propertyName];
      } else {
        validate =
          errors[arrayName] && errors[arrayName]?.content && errors[arrayName]?.content[index] && errors[arrayName]?.content[index][propertyName];
      }
    } else {
      validate = errors[field.name];
    }
    return validate;
  };

  const handleFirstPartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = {
      inputFirstPart: e.target.value,
      inputSecondPart,
    };
    form.setFieldValue(field.name, value);
    setInputFirstPart(e.target.value);
  };
  const handleSecondPartChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = {
      inputFirstPart,
      inputSecondPart: e.target.value,
    };
    form.setFieldValue(field.name, value);
    setInputSecondPart(e.target.value);
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedValues(value);
    }, treshold);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [value]);

  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  useEffect(() => {
    if (debouncedValues) {
      form.setFieldValue(field.name, value);
    }
    // onChange && onChange(debouncedValues);
  }, [debouncedValues]);
  function preventNonNumericalInput(e) {
    e = e || window.event;
    const tempValue = field.value + '';
    const charCode = typeof e.which == 'undefined' ? e.keyCode : e.which;
    const charStr = String.fromCharCode(charCode);
    const isDotFirst = field.value === '' && charStr === '.';
    const isMoreThanOneDot = tempValue?.includes('.') && charStr === '.';
    const isComma = charStr === ',';
    const isUserSelecting = document.getSelection().toString() !== '';
    const isZeroAndNotDot = field.value === '0' && charStr !== '.';
    const isMoreThanTwoDigitsAfterDot = tempValue?.includes('.') && tempValue.split('.')[1].length >= 2 && !isUserSelecting;
    const validation = !charStr.match(/^[0-9./]+$/) || isDotFirst || isMoreThanOneDot || isComma || isZeroAndNotDot || isMoreThanTwoDigitsAfterDot;
    if (validation) e.preventDefault();
    if (field.value === '0' && charStr.match(/^[0-9./]+$/)) {
      form.setFieldValue(field.name, charStr);
    }
  }

  const handleIncrement = () => {
    if (numericStateValue < 10) {
      onBlur(numericStateValue + 1);
      setNumericStateValue(numericStateValue + 1);
      form.setFieldValue(field.name, numericStateValue + 1);
    }
  };

  // Function to handle decrementing the numeric value
  const handleDecrement = () => {
    if (numericStateValue > 2) {
      onBlur(numericStateValue - 1);
      setNumericStateValue(numericStateValue - 1);
      form.setFieldValue(field.name, numericStateValue - 1);
    }
  };

  return (
    <div id={id} className={`input__wrapper ${className} ${form.touched[field.name] && errors[field.name] ? 'input__wrapper--error' : ''}`}>
      <label className="input__label" htmlFor={field.name}>
        {label} {required ? null : <p className="input__optional">(opcjonalne)</p>}
      </label>
      {multi ? (
        <div className="input__double-input">
          <input
            {...field}
            name={field.name}
            className="input"
            value={inputFirstPart}
            onChange={(e) => handleFirstPartChange(e)}
            id={name}
            onBlur={onBlur}
            onFocus={() => inputOnFocus && inputOnFocus()}
            type={type}
          />
          <input {...field} name={field.name} className="input" value={inputSecondPart} onChange={(e) => handleSecondPartChange(e)} type={type} />
        </div>
      ) : (
        <>
          <input
            {...field}
            name={field.name}
            className={`input ${removable ? 'input--removable' : ''}${inputErrorClass}`}
            value={type === 'number' ? numericStateValue : value}
            // value={type === 'number' && className != 'pointerEventsNp' ? numericStateValue : value}
            onChange={(e) => {
              form.setFieldValue(field.name, e.target.value);
              setValue(e.target.value);
              onChange && onChange(e);
            }}
            id={name}
            placeholder={placeholder}
            inputMode={inputMode}
            onBlur={onBlur}
            type={isPassword ? (showPassword ? 'text' : 'password') : type === 'number' ? 'text' : type}
            pattern={pattern}
            maxLength={maxLength}
            onFocus={() => inputOnFocus && inputOnFocus()}
            onKeyPress={(e) => type === 'number' && preventNonNumericalInput(e)}
            onKeyDown={(e) => capsDisabled && e.code === 'Tab' && e.preventDefault()}
            autoComplete={isPassword ? 'off' : 'off'}
          />
          {type === 'number' && (
            <div className="input-number__marks">
              <div
                className={`input-number__mark ${numericStateValue === 2 ? 'input-number__mark--disabled' : ''}`}
                onClick={() => handleDecrement()}>
                <SVG type={SVG_TYPE.MINUS_GRAY} />
              </div>
              <div
                className={`input-number__mark ${numericStateValue === 10 ? 'input-number__mark--disabled' : ''}`}
                onClick={() => handleIncrement()}>
                <SVG type={SVG_TYPE.PLUS_GRAY} />
              </div>
            </div>
          )}
          {isPassword ? (
            <span className="input__change-password" onClick={() => setShowPassword(!showPassword)}>
              <SVG type={showPassword ? SVG_TYPE.EYE : SVG_TYPE.EYE_OFF} />
            </span>
          ) : null}
          {removable ? <span className="input__removable" onClick={() => form.setFieldValue(field.name, '')}></span> : null}
          {isPassword ? <span className="change-password-modal__icon" onClick={() => setShowPassword(!showPassword)}></span> : null}
        </>
      )}
      {errors && form.touched && <div className="input__error">{formTouched(field.name) && <span>{errorField(field.name)}</span>}</div>}
    </div>
  );
}

export default Input;
