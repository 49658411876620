import { TopBar, SVG } from 'Shared/components';
import { SVG_TYPE } from 'Shared/enums';

const GetLost = () => {
  return (
    <div className="page-wrapper">
      <TopBar />
      <div className="get-lost">
        <div className="get-lost__content">
          <SVG type={SVG_TYPE.GET_LOST} />
          <div className="get-lost__header">Ups, zabłądziłeś!</div>
          <div className="get-lost__paragraph">To badanie jest nieaktywne.</div>
        </div>
      </div>
    </div>
  );
};

export default GetLost;
