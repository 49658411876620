import { Modal, Button, Input } from 'Shared/components';
import { Field, Formik } from 'formik';
import { VALIDATION_MESSAGES } from 'Shared/enums';
import * as Yup from 'yup';

type EditUserModalType = {
  user: any;
  onClose: any;
  editUser: (uuid, data) => void;
};

const EditUserModal = ({ user, onClose, editUser }: EditUserModalType) => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(VALIDATION_MESSAGES.REQUIRED).email(VALIDATION_MESSAGES.EMAIL),
    lastName: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN_LENGTH_3)
      .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE),
    firstName: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN_LENGTH_3)
      .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE),
  });

  const handleEditAdministrator = (values) => {
    editUser(user.uuid, values);
    onClose();
  };

  return (
    <Modal className="add-administrator-modal" isOpen={user} onClose={() => onClose()}>
      <div className="modal-heading">Edycja administratora</div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ firstName: user?.firstName, lastName: user?.lastName, email: user?.email }}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={async (values) => handleEditAdministrator(values)}>
        {({ handleSubmit, errors }) => (
          <form className="add-administrator-modal__form" onSubmit={handleSubmit}>
            <Field maxLength={30} label="Imię" name="firstName" placeholder="" required component={Input} errors={errors} />
            <Field maxLength={30} label="Nazwisko" name="lastName" placeholder="" required component={Input} errors={errors} />
            <Field
              label="Email"
              name="email"
              placeholder=""
              required
              component={Input}
              treshold={0}
              maxLength={30}
              errors={errors}
            />
            <div className="add-administrator-modal__buttons">
              <Button label="Anuluj" stroke onClick={() => onClose()} />
              <Button label="Zapisz" onClick={() => handleSubmit()} />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default EditUserModal;
