import { useState } from 'react';
import { Button, SVG } from 'Shared/components';
import { AdministratorsList, AddAdministratorModal } from './components';
import { MainType } from './Container';
import { SVG_TYPE } from 'Shared/enums';

const modalType = {
  ADD: 'add',
  EDIT: 'edit',
  DELETE: 'delete',
};

const AdministratorsPage = (props: MainType) => {
  const isMobile = window.innerWidth < 1024;
  const [modalManage, setModalManage] = useState('');
  const isAddAdministratorModalOpened = modalManage === modalType.ADD;
  const { actions, users, authorizedUser } = props;
  const { getUsers, addUser, editUser, suspendUser, resendActivateLink } = actions;

  const handleCloseModal = () => {
    setModalManage('');
  };

  return (
    <div className="dashboard-content">
      <AddAdministratorModal
        getUsers={getUsers}
        addUser={addUser}
        isModalOpen={isAddAdministratorModalOpened}
        closeModal={() => handleCloseModal()}
      />
      <div className="dashboard-header">
        <div className="dashboard-heading">Użytkownicy</div>
        {isMobile ? (
          <div className="administrator-page__add-user" onClick={() => setModalManage(modalType.ADD)}>
            <SVG type={SVG_TYPE.PLUS} />
          </div>
        ) : (
          <Button label="Dodaj +" onClick={() => setModalManage(modalType.ADD)} />
        )}
      </div>
      <AdministratorsList
        activeUserUuid={authorizedUser?.uuid}
        getUsers={getUsers}
        resendActivateLink={resendActivateLink}
        suspendUser={suspendUser}
        editUser={editUser}
        users={users}
      />
    </div>
  );
};

export default AdministratorsPage;
