import { Modal, Button, Input } from 'Shared/components';
import { Field, Formik } from 'formik';
import { VALIDATION_MESSAGES, ROUTES } from 'Shared/enums';
import * as Yup from 'yup';
import { useNavigate, generatePath } from 'react-router-dom';

type AddAdministratorModalType = {
  isModalOpen: boolean;
  closeModal: () => void;
  getUsers: (data) => void;
  addUser: (data) => any;
};

const AddAdministratorModal = ({ isModalOpen, closeModal, addUser, getUsers }: AddAdministratorModalType) => {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string().required(VALIDATION_MESSAGES.REQUIRED).email(VALIDATION_MESSAGES.EMAIL),
    lastName: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN_LENGTH_3)
      .max(30, VALIDATION_MESSAGES.MAX_LENGTH_30)
      .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE),
    firstName: Yup.string()
      .required(VALIDATION_MESSAGES.REQUIRED)
      .min(3, VALIDATION_MESSAGES.MIN_LENGTH_3)
      .max(30, VALIDATION_MESSAGES.MAX_LENGTH_30)
      .matches(/^[^\s].*[^\s]$/, VALIDATION_MESSAGES.CANNOT_START_AND_END_WITH_SPACE),
  });

  const handleAddAdministrator = async (values, { resetForm }) => {
    const response = await addUser(values);
    if (response) {
      resetForm();
      navigate({ pathname: generatePath(ROUTES.USERS, { page: '1' }) });
      await getUsers({ page: 1 });
      closeModal();
    }
  };

  return (
    <Modal className="add-administrator-modal" isOpen={isModalOpen} onClose={() => closeModal()}>
      <div className="modal-heading">Dodawanie administratora</div>
      <Formik
        validationSchema={validationSchema}
        initialValues={{ firstName: '', lastName: '', email: '' }}
        validateOnBlur={false}
        enableReinitialize
        onSubmit={async (values, props) => handleAddAdministrator(values, props)}>
        {({ handleSubmit, errors, resetForm }) => (
          <form className="add-administrator-modal__form" onSubmit={handleSubmit}>
            <Field maxLength={30} label="Imię" name="firstName" placeholder="" required component={Input} errors={errors} />
            <Field maxLength={30} label="Nazwisko" name="lastName" placeholder="" required component={Input} errors={errors} />
            <Field label="Email" name="email" placeholder="" required component={Input} treshold={0} maxLength={30} errors={errors} />
            <div className="add-administrator-modal__buttons">
              <Button
                label="Anuluj"
                stroke
                onClick={() => {
                  closeModal();
                  resetForm();
                }}
              />
              <Button label="Zapisz" onClick={() => handleSubmit()} />
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddAdministratorModal;
