import { SVG, Loader } from 'Shared/components';

interface ButtonProps {
  label: string;
  onClick?: () => void;

  type?: 'button' | 'submit' | 'reset';
  className?: string;
  svg_type?: string;
  iconClass?: string;
  disabled?: boolean;
  iconOnLeft?: boolean;
  stroke?: boolean;
  black?: boolean;
  fullBlack?: boolean;
  loader?: boolean;
}

function Button({
  label,
  onClick,
  className,
  svg_type,
  iconClass,
  disabled,
  iconOnLeft,
  stroke,
  fullBlack,
  type = 'button',
  black,
  loader = false,
}: ButtonProps) {
  return (
    <button
      type={type}
      className={`btn ${className ? className : ''} ${black ? 'btn--black' : ''} ${fullBlack ? 'btn--full-black' : ''}  ${
        disabled ? 'btn--disabled' : ''
      } ${stroke ? 'btn--stroke' : ''}`}
      onClick={onClick}>
      {iconOnLeft && svg_type ? <SVG type={svg_type} className={iconClass} /> : null}
      <p>{label}</p>
      {loader ? (
        <div className="btn__loader">
          <Loader isWhite width="1" height="1" />
        </div>
      ) : null}
      {!iconOnLeft && svg_type ? <SVG type={svg_type} className={iconClass} /> : null}
    </button>
  );
}

export default Button;
