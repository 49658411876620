import { useEffect } from 'react';
import { MainType } from './Container';
import { DashboardTopBar, DashboardSidebarMenu } from './components';
import { Outlet } from 'react-router';

const Dashboard = (props: MainType) => {
  const { actions } = props;
  const { logout, getAuthorizedUser, changeCurrentAdminPassword } = actions;

  useEffect(() => {
    getAuthorizedUser();
  }, []);

  return (
    <div className="dashboard">
      <DashboardTopBar logout={logout} changeCurrentAdminPassword={changeCurrentAdminPassword} />
      <div className="dashboard-center">
        <DashboardSidebarMenu />
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
