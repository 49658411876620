import { useEffect } from 'react';
import { LoginContent, Input, Button } from 'Shared/components';
import { ROUTES, VALIDATION_MESSAGES } from 'Shared/enums';
import { Field, Formik } from 'formik';
import { Link, useNavigate, generatePath } from 'react-router-dom';
import { MainType } from './Container';
import * as Yup from 'yup';

const LoginPage = (props: MainType) => {
  const navigate = useNavigate();

  const { actions } = props;
  const { login, getAuthorizedUser } = actions;

  useEffect(() => {
    getAuthorizedUser()
      .then((response) => {
        response && navigate({ pathname: generatePath(ROUTES.STUDIES, { page: '1' }) });
      })
      .catch((error) => {
        error;
      });
  }, []);

  const handleLogin = async ({ email, password }) => {
    const response = await login(email, password);
    if (response) {
      navigate({ pathname: generatePath(ROUTES.STUDIES, { page: '1' }) });
    }
  };

  const validation = () =>
    Yup.object().shape({
      email: Yup.string().required(VALIDATION_MESSAGES.REQUIRED).email(VALIDATION_MESSAGES.EMAIL),
      password: Yup.string().min(8, VALIDATION_MESSAGES.PASSWORD_LENGTH).required(VALIDATION_MESSAGES.REQUIRED),
    });

  return (
    <LoginContent heading="Zaloguj się" buttonLabel="Zaloguj się">
      <Formik
        validationSchema={validation}
        initialValues={{ email: '', password: '' }}
        validateOnBlur={false}
        enableReinitialize={true}
        onSubmit={async (values) => handleLogin(values)}>
        {({ handleSubmit, errors }) => (
          <form onSubmit={handleSubmit}>
            <Field label="Email" name="email" placeholder="" required component={Input} treshold={0} maxLength={32} errors={errors} />
            <Field
              label="Hasło"
              name="password"
              placeholder=""
              required
              type="password"
              component={Input}
              treshold={0}
              maxLength={32}
              errors={errors}
              className="login-page__email"
            />
            <Link className="login-page__forget-password" to={ROUTES.FORGOTTEN_PASSWORD}>
              Nie pamiętasz hasła?
            </Link>
            <div className="login-content__button">
              <Button label="Zaloguj się" type="submit" />
            </div>
          </form>
        )}
      </Formik>
    </LoginContent>
  );
};

export default LoginPage;
