import { useState, useEffect, useRef } from 'react';
import { SVG, Badge, Pagination } from 'Shared/components';
import { ROUTES, SVG_TYPE } from 'Shared/enums';
import { useNavigate } from 'react-router';
import { generatePath, useLocation } from 'react-router-dom';
import { DeleteStudy } from 'Screens/StudiesPage/components';
import copy from 'copy-to-clipboard';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

type StudiesListType = {
  tests: any;
  getStudies: (data) => any;
  deleteStudy: (data) => void;
};

const StudiesList = ({ tests, getStudies, deleteStudy }: StudiesListType) => {
  const [isActive, setIsActive] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [tooltipText, setTooltipText] = useState('hide');
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const isMobile = window.innerWidth < 1024;
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const parts = pathname.split('/');
  const lastElement = parts.pop();
  const [currentPage, setCurrentPage] = useState(parseInt(lastElement));
  const containerRef = useRef(null);

  const showBadge = (item) => {
    if (item.status === 'close') {
      return <Badge label="Zamknięte" status="light" />;
    } else {
      if (item.status === 'active') {
        return <Badge label="Aktywne" status="bold" />;
      } else if (item.status === 'new') {
        return <Badge label="Nowe" status="medium" />;
      }
    }
  };

  useEffect(() => {
    if (currentPage.toString() !== lastElement) {
      setCurrentPage(parseInt(lastElement));
    }
  }, [lastElement]);

  const changePage = (page) => {
    setShowDetails(null);
    setTimeout(() => {
      navigate({ pathname: generatePath(ROUTES.STUDIES, { page }) });
    }, 200);
  };

  useEffect(() => {
    getStudies({ page: currentPage });
  }, [currentPage]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isActive !== null) {
        if (!containerRef.current.contains(event.target)) {
          setIsActive(null);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isActive]);

  const handleOpenDeleteModal = (item) => {
    setIsDeleteModalOpen(item);
    setTimeout(() => {
      setIsActive(false);
    }, 50);
  };

  const handleDeleteStudy = async (uuid) => {
    await deleteStudy(uuid);
    setIsDeleteModalOpen(false);
    const response = await getStudies({ page: currentPage });
    if (response.meta.current_page > response.meta.last_page) {
      navigate({ pathname: generatePath(ROUTES.STUDIES, { page: response.meta.last_page }) });
    }
  };

  const showAnswers = (item) => {
    sessionStorage.setItem('study', JSON.stringify({ name: item.name, page: tests?.meta?.current_page || 1 }));
    navigate({ pathname: generatePath(ROUTES.ANSWERS_STUDY, { study: item.link, page: '1' }) });
  };

  const handleSetShowDetails = (idx) => {
    if (idx === showDetails) {
      setShowDetails(null);
    } else {
      setShowDetails(idx);
    }
  };

  const handleOnEdit = (uuid) => {
    sessionStorage.setItem('studiesListPage', JSON.stringify(currentPage));
    navigate({ pathname: generatePath(ROUTES.EDIT_STUDY, { uuid }) });
  };
  ReactTooltip.rebuild();
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [currentPage, tests]);

  const handleClickStudyPreview = (link) => {
    const url = `https://goalmapp.com/${link}`;
    window.open(url, '_blank');
  };

  return (
    <div className="studies-list">
      <div ref={containerRef}>
        <DeleteStudy handleDeleteStudy={handleDeleteStudy} test={isDeleteModalOpen} onClose={() => setIsDeleteModalOpen(false)} />
        {isMobile ? (
          <>
            <div className="test-mobile-list">
              {tests &&
                tests.data &&
                tests.data.length > 0 &&
                tests.data.map((item, idx) => {
                  const minOneAnswer = item.countMaps >= 1;
                  return (
                    <div key={item.uuid} className="test-mobile-list__item">
                      <div className="test-mobile-list__item-top">
                        <div className="test-mobile-list__item-onClick" onClick={() => handleSetShowDetails(idx)} />
                        <div className="test-mobile-list__item-name">{item.name} poka sobe kapitanie janie</div>
                        <div className="test-mobile-list__item-status">{showBadge(item)}</div>
                        <div className="test-mobile-list__item-dots" onClick={() => setIsActive(idx)}>
                          <div className={`administrator-list-settings ${isActive === idx ? 'administrator-list-settings--active' : ''}`}>
                            <SVG type={SVG_TYPE.DOTS} />
                          </div>
                          <div
                            className={`administrator-list-settings__content ${
                              isActive === idx ? 'administrator-list-settings__content--show' : ''
                            }`}>
                            <div className="administrator-list-settings__label" onClick={() => handleClickStudyPreview(item.link)}>
                              Podgląd badania
                            </div>
                            {minOneAnswer && (
                              <div className="administrator-list-settings__label" onClick={() => showAnswers(item)}>
                                Zobacz odpowiedzi
                              </div>
                            )}
                            <div className="administrator-list-settings__label" onClick={() => handleOnEdit(item.uuid)}>
                              Edytuj
                            </div>
                            <div className="administrator-list-settings__label" onClick={() => handleOpenDeleteModal(item)}>
                              Usuń
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`test-mobile-list__item-bottom ${showDetails === idx ? 'test-mobile-list__item-bottom--show' : ''}`}>
                        <div className="test-mobile-list__item-bottom--link">https://goalmapp.com/{item.link}</div>
                        <div className="test-mobile-list__item-bottom--numbers">
                          <div>
                            <p>Wszystkie:</p>
                            <span>{item.countMaps}</span>
                          </div>
                          <div>
                            <p>Zakończone:</p>
                            <span>{item.completedMaps}</span>
                          </div>
                        </div>
                        <div className="test-mobile-list__item-bottom--data">
                          <p>Data utworzenia:</p>
                          <span>{moment(item.createdAt).format('DD/MM/YYYY')}</span>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </>
        ) : (
          <div className="tests-list__table">
            <div className="tests-list__thead">
              <div className="tests-list__table-list">
                <p>Nazwa</p>
                <p className="tests-list__table-list--status">Status</p>
                <p className="tests-list__table-list--data">Data utworzenia</p>
                <p className="tests-list__table-list--number">Wszystkie</p>
                <p className="tests-list__table-list--number">Zakończenie</p>
                <p>Link</p>
                <p></p>
              </div>
            </div>
            <div className="tests-list__tbody">
              {tests &&
                tests.data &&
                tests.data.length > 0 &&
                tests.data.map((item, idx) => {
                  const minOneAnswer = item.countMaps >= 1;
                  return (
                    <>
                      <ReactTooltip id="copy" place="bottom" type="light" effect="solid" className="tooltip" getContent={() => tooltipText} />
                      <div key={item.uuid} className="tests-list__tbody-list">
                        <p className="tests-list__tbody-item tests-list__tbody-item--short">
                          <p>{item.name}</p>
                        </p>
                        <p className="tests-list__tbody-item tests-list__tbody-item--badge">{showBadge(item)}</p>
                        <p className="tests-list__tbody-item tests-list__tbody-item--data">{moment(item.createdAt).format('DD/MM/YYYY')}</p>
                        <p className="tests-list__tbody-item tests-list__tbody-item--number">{item.countMaps}</p>
                        <p className="tests-list__tbody-item tests-list__tbody-item--number">{item.completedMaps}</p>
                        <div className="tests-list__tbody-item tests-list__tbody-item--copy-element">
                          <p className="tests-list__tbody-item--short">
                            <p>https://goalmapp.com/{item.link}</p>
                          </p>
                          <div
                            className="tests-list__tbody-item--copy-icon"
                            data-tip
                            onMouseUp={() => setTooltipText('Kliknij aby skopiować')}
                            onMouseEnter={() => setTooltipText('Kliknij aby skopiować')}
                            onMouseOut={() => setTooltipText('')}
                            data-for={'copy'}
                            onClick={() => {
                              copy(`https://goalmapp.com/${item.link}`);
                              setTooltipText('Link został skopiowany');
                            }}>
                            <SVG type={SVG_TYPE.COPY} />
                          </div>
                        </div>
                        <div className="administrators-list__tbody-item" onClick={() => setIsActive(idx)}>
                          <div className="administrator-list-settings-menu">
                            <div className={`administrator-list-settings ${isActive === idx ? 'administrator-list-settings--active' : ''}`}>
                              <SVG type={SVG_TYPE.DOTS} />
                            </div>
                            <div
                              className={`administrator-list-settings__content ${
                                isActive === idx ? 'administrator-list-settings__content--show' : ''
                              }`}>
                              <div className="administrator-list-settings__label" onClick={() => handleClickStudyPreview(item.link)}>
                                Podgląd badania
                              </div>
                              {minOneAnswer && (
                                <div className="administrator-list-settings__label" onClick={() => showAnswers(item)}>
                                  Zobacz odpowiedzi
                                </div>
                              )}
                              <div className="administrator-list-settings__label" onClick={() => handleOnEdit(item.uuid)}>
                                Edytuj
                              </div>
                              <div className="administrator-list-settings__label" onClick={() => handleOpenDeleteModal(item)}>
                                Usuń
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        )}
        {tests && tests.meta.last_page > 1 && tests.data.length > 0 ? (
          <Pagination
            className="tests-list__pagination"
            totalPages={tests.meta.last_page}
            currentPage={tests.meta.current_page}
            onPageChange={(page) => changePage(page)}
          />
        ) : null}
      </div>
    </div>
  );
};

export default StudiesList;
